import React, {useState, useEffect, KeyboardEvent, useRef} from 'react';
import {
    TextField,
    Paper,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Typography,
    Chip,
    Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {PropsComponenteDinamico} from "types/PropsComponenteDinamico";
import {useConversacionContext} from "hooks/useConversacionContext";
import Plot from 'react-plotly.js';


const VisualizadorPlotly = (props: PropsComponenteDinamico) => {
    const { payload } = props;

    // useEffect para hacer focus pero es conflictivo con el messagecomposer
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (inputRef.current) {
    //             inputRef.current.focus();
    //         }
    //     }, 1000);
    //
    //     // Cleanup function to clear the timeout if the component unmounts
    //     return () => clearTimeout(timer);
    // }, []);

    const [data, setData] = useState([]);
    const [config, setConfig] = useState({});
    const [layout, setLayout] = useState({});

    // Layout por defecto
    const defaultLayout = {
        autosize: true,
        margin: { l: 50, r: 50, b: 50, t: 50, pad: 4 },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        font: {
            family: 'Arial, sans-serif',
            size: 12,
            color: '#333'
        },
        width: 600,  // Ancho predeterminado
        height: 400  // Altura predeterminada
    };

    // Configuración por defecto
    const defaultConfig = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToAdd: [
            // 'zoom2d',
            // 'pan2d',
            // 'select2d',
            // 'lasso2d',
            'fullscreen',
            'zoomIn2d',
            'zoomOut2d',
            'autoScale2d',
            'resetScale2d',
            // 'autoScale2d',
            // 'resetScale2d',
            // 'hoverClosestCartesian',
            // 'hoverCompareCartesian',
            // 'toggleSpikelines',
            'toImage',

        ],
        modeBarButtonsToRemove: [
            'autoScale2d',
            'resetScale2d',
            'drawrect',
        ],
        toImageButtonOptions: {
            format: 'png', // Puedes cambiar a 'svg', 'jpeg', etc.
            filename: 'custom_image',
            height: 500,
            width: 700,
            scale: 1 // Multiplicador de escala
        }
    };

    useEffect(() => {
        // console.log("payload", payload);

        if (payload["data"]) {
            setData(payload["data"]);
        }

        if (payload["layout"]) {
            // Fusionar el layout del payload con el layout por defecto
            setLayout({ ...defaultLayout, ...payload["layout"] });
        } else {
            setLayout(defaultLayout);
        }

        if (payload["config"]) {
            setConfig({ ...defaultConfig, ...payload["config"] });
        } else {
            setConfig(defaultConfig);
        }



    }, [payload]);

    return (
        <Box sx={{
            display: 'inline-block',
            p: 2,
            border: '1px solid #ccc',
            borderRadius: '8px',
            mt: 3,
            mb: 4,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            maxWidth: '100%',
            overflow: 'hidden'
        }}>
            <Plot
                data={data}
                layout={layout}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
                config={config}
            />
        </Box>
    );
};

export default VisualizadorPlotly;