import React, {useEffect, useState, useRef} from "react";
import {
    Box,
    TextField,
    Button,
    Container,
    Grid,
    LinearProgress,
    CircularProgress,
    InputAdornment,
    TextareaAutosize,
    Typography,
    Chip
} from "@mui/material";
import AttachFile from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";
import {useDropzone} from 'react-dropzone';
import {useTheme} from '@mui/material/styles';
import {useConversacionContext} from "hooks/useConversacionContext";
import useMediaQuery from "@mui/material/useMediaQuery";

type MessageComposerProps = {
    handleSendMessage: CallableFunction;
    handleStopRun: CallableFunction;
    isWaiting: boolean;
    isEmbed?: boolean;
    setIsWaiting: CallableFunction;
    assistant: any;
    inputError: boolean;
    nuevaConversacion: boolean;
}

const MessageComposer = (props: MessageComposerProps) => {

    const {handleSendMessage, handleStopRun, isWaiting, setIsWaiting, isEmbed, assistant, inputError, nuevaConversacion} = props;
    const theme = useTheme();
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        nuevoMensajeInput,
        setNuevoMensajeInput,
        nuevoMensajeArchivos,
        setNuevoMensajeArchivos,
        focus,
        setFocus,
        conversacionCerrada,
        nuevoMensajeAdjuntos,
        setNuevoMensajeAdjuntos,
        nuevoMensajeRunId
    } = useConversacionContext();

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Evita la creación de una nueva línea
            handleSendMessage();
        }
    };


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: (acceptedFiles, fileRejections, event) => {
            let prevFiles = nuevoMensajeArchivos;
            if (!prevFiles) {
                prevFiles = [];
            }

            setNuevoMensajeArchivos([...prevFiles, ...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))]);
        }
    });

    const eliminarAdjunto = (index: number) => {
        setNuevoMensajeAdjuntos(prevAdjuntos => prevAdjuntos.filter((_, i) => i !== index));
    };

    // Renderizar los adjuntos
    const renderAdjuntos = () => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
            {nuevoMensajeAdjuntos.map((adjunto, index) => (
                <Chip
                    key={index}
                    label={adjunto.textoVisible}
                    onDelete={() => eliminarAdjunto(index)}
                    size="small"
                />
            ))}
        </Box>
    );


    useEffect(() => {
        //console.log("focus", focus);
        if (focus && inputRef.current) {
            inputRef.current.focus();
            setFocus(false);
        }
    }, [focus]);

    useEffect(() => {
        if (!isWaiting) {
            setTimeout(() => {
                setFocus(true);
            }, 1000);
        }
    }, [isWaiting]);

    useEffect(() => {
        setTimeout(() => {
            setFocus(true);
        }, 1000);
    }, []);


    const hasAdjuntos = nuevoMensajeAdjuntos.length > 0;


    return (
        <Box style={{
            backgroundColor: 'transparent',
            padding: !isEmbed ? "10px 0" : "10px 0 0"
        }}>

            {nuevoMensajeAdjuntos.length > 0 && renderAdjuntos()}

            <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                gap: 2,
                width: '100%'
            }}>
                <TextareaAutosize
                    minRows={1}
                    ref={inputRef}
                    style={{
                        flexGrow: 1,
                        minHeight: '25px',
                        padding: '15px',
                        borderRadius: '5px',
                        borderColor: inputError ? 'red' : '#E3E3E3',
                        animation: inputError ? 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both' : 'none',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        lineHeight: '1.5',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '16px',
                        resize: 'vertical'
                    }}
                    disabled={isWaiting}
                    placeholder="Escribí tu mensaje..."
                    value={nuevoMensajeInput}
                    onChange={(e) => setNuevoMensajeInput(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <Box mb={0.5}>
                {(isWaiting && nuevoMensajeRunId) ? (
                    <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        onClick={() => handleStopRun()}
                        sx={{
                            minHeight: "3.2em",
                            minWidth: "3.2em",
                            borderRadius: '5px',
                            background: theme.palette.secondary.main,
                        }}
                    >
                        <StopIcon sx={{
                            fontSize: "1.5rem",
                        }}/>
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={() => handleSendMessage()}
                        disabled={isWaiting ? true : false}
                        sx={{
                            minHeight: "3.2em",
                            minWidth: "3.2em",
                            borderRadius: '5px',
                            background: theme.palette.primary.main,
                        }}
                    >
                        <SendIcon sx={{
                            fontSize: "1.5rem",
                        }}/>
                    </Button>
                )}
                </Box>
            </Box>

            {(assistant && (assistant.upload_habilitado == true)) && (
                <Box>
                    {isWaiting ? <LinearProgress color="inherit"/> : (


                        <div {...getRootProps()}
                             className={isDragActive ? 'drag-zone drag-active' : 'drag-zone'}
                             onDragOver={(event) => {
                                 event.preventDefault();
                             }}
                             style={{marginBottom: isMobile ? 0 : "20px"}}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ?
                                <Typography sx={{fontSize: "90%"}}>Suelta los archivos aquí ...</Typography> :
                                <Typography sx={{fontSize: "90%"}}>Arrastra y suelta algunos archivos aquí, o haz clic
                                    para seleccionar archivos</Typography>}

                            {nuevoMensajeArchivos.length > 0 && (
                                <ul>
                                    {nuevoMensajeArchivos.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}

                </Box>

            )}

        </Box>
    )
}

export default MessageComposer;