import {useCallback, useContext, useState, useMemo} from 'react';
import {useAppContext} from 'hooks/useAppContext';
import useFetchAPI from "./useFetchApi";
import {Conversacion} from "../types/Conversacion";
import { differenceInCalendarDays } from 'date-fns';

const groupConfig = [
    { key: 'Hoy', condition: (diffDays) => diffDays <= 0 },
    { key: 'Ayer', condition: (diffDays) => diffDays === 1 },
    { key: 'Ultimos 7 días', condition: (diffDays) => diffDays <= 7 },
    { key: 'Ultimos 30 días', condition: (diffDays) => diffDays <= 30 },
    { key: 'Anterior', condition: (diffDays) => diffDays > 30 }
];

const useConversaciones = () => {
    const {
        cuentaSeleccionada,
        setApiAccesible,
        conversaciones,
        setConversaciones,
        conversacionSeleccionada,
        setErrorSistema
    } = useAppContext(); // Usar el contexto para acceder a la cuenta seleccionada y setters

    const fetchAPI = useFetchAPI(); // Asume que ya tienes esta función

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const DEFAULT_PAGE_SIZE = 20;


    // Función para inicializar o actualizar las conversaciones
    const actualizarConversaciones = useCallback(async (accountId = null, reset = false) => {
        console.log("actualizando conversaciones", reset);
        if (reset) {
            setPage(1);
            setConversaciones(null);
        }

        if (!accountId && cuentaSeleccionada) {
            accountId = cuentaSeleccionada.id;
        }
        if (!accountId) {
            console.error("No se ha proporcionado un accountId y no hay cuenta seleccionada.");
            return;
        }

        // console.log("actualizando conversaciones");
        // console.log("pagina", page);

        try {
            let url = `/conversations/list/${accountId}`;
            let pagination = true;
            let per_page = DEFAULT_PAGE_SIZE;
            url += `?pagination=${pagination}&page=${page}&per_page=${per_page}`;

            const response = await fetchAPI(url, 'GET');
            if (!response) {
                setApiAccesible(false);
                return;
            }

            let data = response;
            if (!data["conversations"] || data["conversations"].length === 0) {
                // si no habia conversaciones inicializo el array en cero
                if (!conversaciones) {
                    setConversaciones([]);
                }
                
                setHasMore(false);
            } else {
                const newConversations = data["conversations"] as Conversacion[];
                setConversaciones(
                    conversaciones === null || reset
                        ? newConversations
                        : [...conversaciones, ...newConversations]
                );
                setPage(prevPage => prevPage + 1);
            }
        } catch (error) {
            setErrorSistema(error.message);
        }
    }, [setConversaciones, conversaciones, setErrorSistema]);

    const actualizarTituloConversacion = async (conversacionId, titulo) => {
        const formData = new FormData();
        formData.append('titulo', titulo);

        let data = await fetchAPI(`/conversations/${conversacionId}/update-title`, 'POST', formData);

        if (!data) {
            console.error('Error al actualizar el título de la conversación');
            return false;
        }

        // actualizarConversaciones();
        actualizarTituloLocal(conversacionId, titulo);
    }

    const eliminarConversacion = useCallback(async (conversacionId: number = null) => {
        if (!conversacionId) {
            conversacionId = conversacionSeleccionada.id;
        }
        let data = await fetchAPI(`/conversations/${conversacionId}/delete`);

        if (!data) {
            console.error('Error al eliminar la conversación');
            return false;
        }

    }, [conversacionSeleccionada]);

    const eliminarConversacionLocal = (conversacionId: string): boolean => {
        // console.log("eliminando conversacion local", conversacionId);
        let conversacionesCopy = [...conversaciones];
        for (let i = 0; i < conversacionesCopy.length; i++) {
            if (conversacionesCopy[i].id === conversacionId) {
                conversacionesCopy.splice(i, 1);
                // console.log("eliminamos idx", i);
                setConversaciones(conversacionesCopy);
                return true
            }
        }

        return false;
    }

    const actualizarTituloLocal = (conversacionId: string, nuevoTitulo: string): boolean => {
        // console.log("eliminando conversacion local", conversacionId);
        let conversacionesCopy = [...conversaciones];
        for (let i = 0; i < conversacionesCopy.length; i++) {
            if (conversacionesCopy[i].id === conversacionId) {
                conversacionesCopy[i].titulo = nuevoTitulo;
                // console.log("eliminamos idx", i);
                setConversaciones(conversacionesCopy);
                return true
            }
        }

        return false;
    }

    const agruparConversacionesPorFecha = useMemo(() => {
        if (!conversaciones || conversaciones.length === 0) {
            return {};
        }

        const groups = groupConfig.reduce((acc, group) => {
            acc[group.key] = [];
            return acc;
        }, {});

        const now = new Date();

        conversaciones.forEach(conversation => {
            const conversationDate = new Date(conversation.fecha_ultimo_mensaje);
            const diffInDays = differenceInCalendarDays(now, conversationDate);

            for (const group of groupConfig) {
                if (group.condition(diffInDays)) {
                    groups[group.key].push(conversation);
                    break;
                }
            }
        });

        // Ordenar las conversaciones dentro de cada grupo
        Object.keys(groups).forEach(key => {
            groups[key].sort((a, b) => {
                const dateA = new Date(a.fecha_ultimo_mensaje);
                const dateB = new Date(b.fecha_ultimo_mensaje);
                return dateB.getTime() - dateA.getTime(); // Orden descendente (más nuevo primero)
            });
        });

        return groups;
    }, [conversaciones]);

    const contarConversacionesAgrupadas = useCallback(() => {
        if (!agruparConversacionesPorFecha) {
            return false;
        }
        let keys = Object.keys(agruparConversacionesPorFecha);
        let total = keys.reduce((acum, key) => acum + agruparConversacionesPorFecha[key].length, 0);
        // console.log("total", total);
        return total > 0;
    }, [agruparConversacionesPorFecha])

    // Devolver la función para que pueda ser usada externamente
    return {
        actualizarConversaciones,
        eliminarConversacion,
        actualizarTituloConversacion,
        eliminarConversacionLocal,
        actualizarTituloLocal,
        conversacionesAgrupadas: agruparConversacionesPorFecha,
        contarConversacionesAgrupadas,
        hasMore,
        setPage,
        page
    };
};

export default useConversaciones;
