// src/components/Message.tsx
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {MessageDto} from "../models/MessageDto";
import MarkdownIt from 'markdown-it';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faRobot} from '@fortawesome/free-solid-svg-icons';
import {
    Box,
    Typography,
    Paper,
    Chip,
    Grid,
    AlertColor,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    keyframes, LinearProgress
} from '@mui/material';
import {
    ThumbUp,
    ThumbDown,
    ThumbUpAltOutlined,
    ThumbDownAltOutlined,
    Star,
    StarBorder,
    Comment
} from '@mui/icons-material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {IconButton} from '@mui/material';
import Modal from '@mui/material/Modal';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css'; // O el tema que prefieras
import Loader from "./Loader";

import {Agriculture, CheckCircle, DoneOutline, EnergySavingsLeaf, Spa} from "@mui/icons-material";
import {HourglassEmpty, Autorenew, Android, HelpOutline} from "@mui/icons-material";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import "./Message.css";
import {Link} from "@mui/material";
import Message from "./Message";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import useFetchAPI from "hooks/useFetchApi";
import moment from "moment-timezone";
import {useAppContext} from "../hooks/useAppContext";
import {useConversacionContext} from "../hooks/useConversacionContext";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MessageErrorPanel from "./MessageErrorPanel";

interface MessageProps {
    messages: any;
    lastGroup?: any;
    lastMessage?: MessageDto;
    conversationId: string;
    isBuildingPanel?: boolean;
    isWaiting?: boolean;
    buildingToolState?: number;
    buildingToolInfo?: any;
    runStepError?: boolean | string;
    assistant?: any;
}


interface ActionIconsProps {
    isUser: boolean;
    message?: MessageDto;
    initialFavorite?: boolean;
}

type PasosEjecucionProps = {
    infoMensaje: any;
}

/**
 * Recibe un listado de mensajes del mismo rol (chat o usuario) y los muestra agrupados
 *
 * Props:
 * - messages: MessageDto[] - The messages to be displayed.
 * - conversationId: string - The ID of the conversation the messages belong to.
 *
 * This component is used to display a panel of messages in the user interface.
 * @param messages
 * @param conversationId
 * @param lastMessage
 * @param assistant
 * @constructor
 * MessagesPanel Component
 */
const MessagesPanel: React.FC<MessageProps> = (props) => {
    const {
        messages,
        lastGroup,
        conversationId,
        isBuildingPanel,
        assistant,
        buildingToolState,
        runStepError,
        buildingToolInfo,
        isWaiting
    } = props;
    const isUser = messages[0].isUser;
    const [open, setOpen] = React.useState(false);
    const [infoMensajeLoading, setInfoMensajeLoading] = React.useState(false);
    const [infoMensaje, setInfoMensaje] = React.useState<any>(null);
    const [toolCall, setToolCall] = React.useState<any>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {conversacionSeleccionada} = useAppContext()

    const fetchAPI = useFetchAPI();

    const handleOpen = async () => {
        if (!conversacionSeleccionada || (!lastGroup && !messages)) {
            return;
        }

        setInfoMensajeLoading(true);
        setOpen(true);

        let mensajes = [];
        let mensajeId = null;
        // console.log("lastGroup", lastGroup);
        // console.log("messages", messages);

        if (messages && messages[0].id) {
            mensajeId = messages[0].id;
        } else if (lastGroup[lastGroup.length - 1] && lastGroup[lastGroup.length - 1].id) {
            mensajeId = `${lastGroup[lastGroup.length - 1].id}`;
        }

        if (!mensajeId) {
            // console.log("no hay nada para ver");
            setInfoMensajeLoading(false);
            return;
        }

        let response = await fetchAPI(`/conversations/${conversacionSeleccionada.id}/messages/${mensajeId}`);

        if (response) {
            setInfoMensaje(response);
        }

        setInfoMensajeLoading(false);
    }
    const handleClose = () => {
        setOpen(false);
    }


    const obtenerFechaCreacionPorTimezone = (message: MessageDto) => {
        if (!message.fecha_creacion) {
            return '';
        }

        const timestampInMilliseconds = Number(message.fecha_creacion) * 1000;
        const date = moment.tz(timestampInMilliseconds, "America/Argentina/Buenos_Aires");
        return date.format('DD/MM/YYYY HH:mm');
    }


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: "left",
            paddingBottom: isWaiting ? "20px" : "0px",
        }}>
            {/* Se agrega un div flexbox para que se pueda tener un espaciado entre los iconos y las respuestas en si*/}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
            }}>
                <div>
                    <Box display={"inline-flex"} flexDirection={"row"}
                         style={{
                             paddingTop: "15px",
                             color: 'black',
                             width: 'fit-content'
                         }}>
                        {isUser ? (
                            <FontAwesomeIcon icon={faUser} title={"#"}
                                             style={{
                                                 backgroundColor: theme.palette.secondary.main,
                                                 borderRadius: '100%',
                                                 height: '1.25em',
                                                 width: '1.25em',
                                                 padding: '0.5em',
                                                 color: '#FFFFFF'
                                             }}
                            />) : (
                            <FontAwesomeIcon icon={faRobot} title={"#"}
                                             style={{
                                                 backgroundColor: theme.palette.primary.main,
                                                 borderRadius: '100%',
                                                 height: '1.25em',
                                                 width: '1.25em',
                                                 padding: '0.5em',
                                                 color: '#FFFFFF'
                                             }}
                                             onClick={() => {
                                                 handleOpen();
                                             }}
                            />)}
                    </Box>
                </div>
                <div
                    style={{
                        flex: 11,
                        padding: "15px 15px 0",
                        borderRadius: "8px",
                        marginLeft: '0px',
                        marginTop: '5px',
                        marginRight: '0',
                        marginBottom: '0',
                    }}
                >

                    <div>
                        {isUser ? (
                            <Box display={"inline-flex"} flexDirection={"row"}
                                 style={{
                                     color: 'black',
                                     width: 'fit-content'
                                 }}>
                                <Typography
                                    fontWeight={"bold"}
                                    fontFamily={"inter"}
                                    fontSize={"1.2rem"}
                                    title={messages && messages.length > 0 ? obtenerFechaCreacionPorTimezone(messages[0]) : 'lala'}
                                    style={{margin: 'auto'}}>Vos</Typography>
                            </Box>
                        ) : (

                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                style={{
                                    color: 'black',
                                }}
                            >
                                <Typography
                                    fontWeight="bold"
                                    fontFamily="inter"
                                    fontSize="1.2rem"
                                    title={messages && messages.length > 0 ? obtenerFechaCreacionPorTimezone(messages[0]) : 'lala'}
                                >
                                    {assistant.nombre}
                                </Typography>

                                {isBuildingPanel && isWaiting && (
                                    <Box display="flex" alignItems="center">
                                        {buildingToolInfo && (
                                            <Typography variant="caption" sx={{mr: 1}}>
                                                {buildingToolInfo.data.caption ? buildingToolInfo.data.caption : (
                                                    buildingToolInfo.data.tipo === "function"
                                                        ? "Accediendo a los datos..."
                                                        : buildingToolInfo.data.tipo === "code_interpreter"
                                                            ? "Realizando el análisis..."
                                                            : "Ejecutando..."
                                                )}
                                            </Typography>
                                        )}
                                        <CircularProgress color="inherit" size={18}/>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </div>
                </div>

            </div>

            <div>

                <div style={{paddingLeft: isMobile ? 0 : "50px"}}>

                    {messages.map((message, index) => {
                        let lastMessage = index > 0 ? messages[index - 1] : null;
                        let isLastMessage = index === messages.length - 1;
                        return (
                            <Message key={index}
                                     message={message}
                                     lastMessage={null}
                                     isLastMessage={isLastMessage}
                                     conversationId={conversationId}
                                     isBuildingPanel={isBuildingPanel}/>
                        )
                    })}

                    {/*{isBuildingPanel && (*/}
                    {/*    <>*/}
                    {/*        {buildingToolState > 0 ? (*/}
                    {/*            <Box display={"flex"} flexDirection={"column"} width="100%">*/}
                    {/*                <FunctionCallingBox estado={buildingToolState} info={buildingToolInfo}/>*/}
                    {/*            </Box>*/}
                    {/*        ) : (*/}
                    {/*            <Box display={"flex"} flexDirection={"column"} width="100%" style={{minHeight: "50px"}}>*/}
                    {/*                &nbsp;*/}
                    {/*            </Box>*/}
                    {/*        )}*/}
                    {/*    </>*/}

                    {/*)}*/}


                    {isBuildingPanel && runStepError && (
                        <MessageErrorPanel mostrarReload={false} mensajeError={runStepError as string}/>
                    )}

                    {!isBuildingPanel && messages.length > 0 && (
                        <ActionIcons isUser={isUser} message={messages[0]}/>
                    )}

                    {/*<Link onClick={verPasosIntermedios} style={{marginTop: "10px", fontSize: "0.9rem"}} href={"#"}>Ver pasos intermedios</Link>*/}
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    border: '1px solid #fafaf8',
                    p: 2
                }}>
                    {infoMensajeLoading || !infoMensaje ? (
                        <Loader notFullHeight={true}/>
                    ) : (
                        <VisorPasosEjecucion infoMensaje={infoMensaje}/>
                    )}

                </Box>
            </Modal>
        </div>

    );
};

const ActionIcons: React.FC<ActionIconsProps> = ({isUser, message = null, initialFavorite = false}) => {
    const [isFavorite, setIsFavorite] = useState(initialFavorite);
    const [valoracion, setValoracion] = useState(message?.valoracion);
    const {addNotification} = useAppContext();
    const fetchAPI = useFetchAPI();

    useEffect(() => {
        if (!message) {
            return;
        }

        if (message.esFavorito) {
            setIsFavorite(true);
        }
    }, [message]);

    const toggleFavorite = async () => {
        let data = await fetchAPI(`/messages/${message.id}/marcar-favorito`, 'POST');
        if (data && data.status === "ok") {
            addNotification("Se marcó/desmarcó como favorito correctamente", "success");
            setIsFavorite(!isFavorite);
        }


        // Aquí puedes añadir la lógica para guardar el estado de favorito en tu backend
    };

    const handleThumbUp = async () => {
        if (valoracion == 1) {
            return;
        }

        let data = await fetchAPI(`/messages/${message.id}/cambiar-valoracion/1`, 'POST');
        if (data && data.status === "ok") {
            addNotification("Gracias por darnos feedback!", "success");
            setValoracion(1);
        }
    }

    const handleThumbDown = async () => {
        if (valoracion == -1) {
            return;
        }

        let data = await fetchAPI(`/messages/${message.id}/cambiar-valoracion/-1`, 'POST');
        if (data && data.status === "ok") {
            addNotification("Gracias por darnos feedback!", "success");
            setValoracion(-1);
        }
    }

    const handleComment = () => {
        addNotification("La funcionalidad no se han implementado todavía", "info");
    }

    if (message.id === "xxx-new") {
        return;
    }

    return (

        <Box display="flex" justifyContent="flex-start" mt={-1} ml={-1} zIndex={0}>
            <IconButton size="medium" title="Marcar como favorito" onClick={toggleFavorite}>
                {isFavorite ? (
                    <Star fontSize="medium" sx={{fontSize: '0.9rem', color: 'gold'}}/>
                ) : (
                    <StarBorder fontSize="medium" sx={{fontSize: '0.9rem'}}/>
                )}
            </IconButton>
            {!isUser && (
                <>
                    <IconButton size="medium" title="Respuesta correcta" onClick={handleThumbUp}>
                        {valoracion === 1 ? (
                            <ThumbUp fontSize="medium" sx={{fontSize: '0.9rem'}}/>
                        ) : (
                            <ThumbUpAltOutlined fontSize="medium" sx={{fontSize: '0.9rem'}}/>
                        )}

                    </IconButton>
                    <IconButton size="medium" title="Respuesta incorrecta" onClick={handleThumbDown}>
                        {valoracion == -1 ? (
                            <ThumbDown fontSize="medium" sx={{fontSize: '0.9rem'}}/>
                        ) : (

                            <ThumbDownAltOutlined fontSize="medium" sx={{fontSize: '0.9rem'}}/>
                        )}

                    </IconButton>
                </>
            )}

            {/*<IconButton size="medium" title="Enviar un mensaje" onClick={handleComment}>*/}
            {/*    <Comment fontSize="medium" sx={{fontSize: '0.9rem'}}/>*/}
            {/*</IconButton>*/}
        </Box>
    );
};

const FunctionCallingBox = (props: { estado: number, info: any }) => {
    const {estado, info} = props;
    const [severity, setSeverity] = React.useState("info");
    const [index, setIndex] = React.useState(0);
    const [opacity, setOpacity] = React.useState(1);
    const theme = useTheme();
    const {accountConfig} = useAppContext();

    const mensajesLoading = useMemo(() => {
        if (!accountConfig || !accountConfig.messagesPanel?.loadingMessages) {
            return [
                "<strong>Ejecutando función</strong> Solo un poco más de paciencia.",
            ];
        }

        return accountConfig.messagesPanel.loadingMessages;
    }, [accountConfig]);

    const icons = useMemo(() => {
        if (!accountConfig || !accountConfig.messagesPanel?.loadingIcons) {
            return [
                <Android/>
            ];
        }

        return accountConfig.messagesPanel.loadingIcons;
    }, [accountConfig]);

    useEffect(() => {
        setIndex(Math.floor(Math.random() * mensajesLoading.length));
    }, []);

    useEffect(() => {
        if (estado === 1) {
            const timer = setInterval(() => {
                setOpacity(0);
                setTimeout(() => {
                    setIndex((prevIndex) => (prevIndex + 1) % mensajesLoading.length);
                    setOpacity(1);
                }, 500);
            }, 10000);
            return () => clearInterval(timer);
        }
    }, [estado]);


    return (
        <Box flex={1} my={2}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <LinearProgress
                    color="inherit"
                    sx={{width: 100, mb: 1}}
                />
                {info && (
                    <Typography variant="caption" sx={{ml: 0}}>
                        {info.data.tipo === "function" ? "Accediendo a los datos..." : info.data.tipo === "code_interpreter" ? "Realizando el análisis..." : "Ejecutando..."}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

const VisorPasosEjecucion = (props: PasosEjecucionProps) => {
    const {infoMensaje} = props;
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{width: '90%', maxWidth: 800, bgcolor: 'background.paper', p: 2, my: 1}} className={"detalle_step"}>
            <Typography variant="h5" gutterBottom>
                Run: {infoMensaje["message"]["run_id"]}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Steps
            </Typography>
            {infoMensaje["run"]["steps"].slice().reverse().map((step: any, index: number) => (
                <Accordion key={"step_" + index} expanded={expanded === "panel" + index}
                           onChange={handleChange("panel" + index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={"panel" + index + "bh-content"}
                        id={"panel" + index + "bh-header"}
                    >
                        <Typography sx={{flexShrink: 0}}>
                            <Typography fontWeight={"bold"} display={"inline"}> {index + 1}. {step["type"]}</Typography>
                            {step["details"][0] && step["details"][0]["type"] && (
                                <span> - {step["details"][0]["type"]}</span>
                            )}
                            : {step["id"]}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        {step["details"].map((detail: any, detailIndex: number) => (
                            <Box sx={{mt: 1}} key={"detail_" + detailIndex}>
                                <Typography variant="body1">
                                    {detail["type"]}: #{detail["id"]}
                                </Typography>
                                {["code_interpreter", "function"].includes(detail["type"]) && (
                                    <Box>
                                        <Box component="pre" sx={{
                                            display: 'block',
                                            p: 1,
                                            bgcolor: 'grey.200',
                                            overflow: 'auto',
                                            maxHeight: 120
                                        }}>
                                            {detail["input"]}
                                        </Box>
                                        <Box component="pre" sx={{
                                            display: 'block',
                                            p: 1,
                                            bgcolor: 'grey.200',
                                            overflow: 'auto',
                                            maxHeight: 120,
                                            mt: 1
                                        }}>
                                            {detail["outputs"]}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}

export default MessagesPanel;