import {MessageDto} from "../models/MessageDto";
import {Box} from "@mui/material";
import MessagesPanel from "./MessagesPanel";
import React from "react";
import {useConversacionContext} from "../hooks/useConversacionContext";

interface ListProps {
    gruposDeMensajes?: any[];
    conversationId: string;
    asistenteSeleccionado: any;
    isWaiting: boolean;
}

/**
 * Componente que muestra la lista de mensajes cargada por el chat
 * @param props
 * @constructor
 */
const MessagesList: React.FC<ListProps> = (props) => {
    const {gruposDeMensajes, conversationId, asistenteSeleccionado, isWaiting } = props;
    const {
        nuevoMensajeToolCall,
        nuevoMensajeRunStepError,
        nuevoMensajeToolInfo,
        mensajeEnConstruccion
    } = useConversacionContext();

    if (!gruposDeMensajes) {
        return null;
    }

    const reversedGruposDeMensajes = [...gruposDeMensajes].reverse();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column-reverse',
                height: 'calc(100% - 80px)',
                overflowY: 'auto'
            }}
        >


            {mensajeEnConstruccion && (
                <MessagesPanel
                    conversationId={conversationId}
                    messages={[mensajeEnConstruccion]}
                    lastGroup={gruposDeMensajes[gruposDeMensajes.length - 1]}
                    assistant={asistenteSeleccionado}
                    isBuildingPanel={true}
                    isWaiting={isWaiting}
                    buildingToolState={nuevoMensajeToolCall}
                    buildingToolInfo={nuevoMensajeToolInfo}
                    runStepError={nuevoMensajeRunStepError}
                    key={"index-grouped-" + 1000}/>
            )}


            {reversedGruposDeMensajes.map((groupMessages, index) => {
                let lastGroup = index < reversedGruposDeMensajes.length - 1 ? reversedGruposDeMensajes[index + 1] : null;
                return (
                    <MessagesPanel
                        conversationId={conversationId}
                        messages={groupMessages}
                        lastGroup={lastGroup}
                        isBuildingPanel={false}
                        isWaiting={isWaiting}
                        assistant={asistenteSeleccionado}
                        key={"index-grouped-" + index}
                    />
                )
            })}
        </Box>
    )
}

export default MessagesList;