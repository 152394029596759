// src/components/Notification.tsx
import React from 'react';
import { Snackbar, IconButton, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface NotificationProps {
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
    onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ open, message, severity, onClose }) => {
    const getColor = () => {
        switch (severity) {
            case 'success': return '#4caf50';
            case 'info': return '#2196f3';
            case 'warning': return '#ff9800';
            case 'error': return '#f44336';
            default: return '#2196f3';
        }
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <SnackbarContent
                style={{ backgroundColor: getColor() }}
                message={message}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Snackbar>
    );
};

export default Notification;