import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type LoaderProps = {
    notFullHeight?: boolean;
}

/**
 * Loader Component
 *
 * Props:
 * - notFullHeight: boolean - Flag to indicate if the loader should not take the full height.
 *
 * This component is used to display a loading spinner in the center of the screen or within a smaller container.
 */
const Loader = (props:LoaderProps) => {
    const {notFullHeight} = props;
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: !notFullHeight ? '100vh' : "100px", width: "100%" }}>
            <CircularProgress />
        </div>
    );
};

export default Loader;
