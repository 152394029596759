import {useTheme} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import React, {useEffect, useState} from "react";
import './SidebarStickyHeader.css';

const StickyDateHeader = ({group, isVisible = true, isTitle = false}) => {
    const theme = useTheme();
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isTitle) {
            setAnimate(true);
            const timer = setTimeout(() => setAnimate(false), 300); // Duración de la animación
            return () => clearTimeout(timer);
        }
    }, [group, isTitle]);


    return (
        <Box
            sx={{
                position: 'sticky',
                top: 0,
                zIndex: isTitle ? 2 : 1,
                backgroundColor: theme.palette.backgroundSidebar.main,
                padding: "10px 15px",
                alignItems: 'center',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.2s',
                pointerEvents: isVisible ? 'auto' : 'none',
                color: 'darkgrey',
                display: isVisible ? 'flex' : 'none',
            }}
        >
            <ChatBubbleOutlineOutlinedIcon style={{
                paddingRight: '5px',
                height: '16px',
                width: '16px'
            }}/>
            <Typography
                variant="h4" sx={{
                margin: '0px',
                textTransform: 'uppercase',
                fontSize: '1rem'
            }}
                className={`sticky-header ${isTitle ? 'title' : ''} ${animate ? 'animate' : ''}`}
            >
                {group.label}
            </Typography>
        </Box>
    );
};

export default StickyDateHeader;