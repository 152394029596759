import {
    Button,
    Grid,
    Drawer,
    List,
    Typography,
    Box,
    IconButton,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import React, {useEffect, useState, useCallback, useMemo, useRef} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";
import {useTheme} from '@mui/material/styles';
import './Sidebar.css';
import {Helmet} from 'react-helmet';
import {useAppContext} from "hooks/useAppContext";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {useAuthContext} from "contexts/AuthContext";
import PanelUsuario from "./PanelUsuario";
import useSidebarLayout from "hooks/useSidebarLayout";
import useSidebarList from "hooks/useSidebarList";
import SidebarConversationsList from "./SidebarConversationList";
import {AddComment, RateReview} from "@mui/icons-material";
import {debounce} from 'lodash';

type SidebarProps = {
    mostrarSwitcher?: boolean;
    mostrarSidebar?: boolean;
}
type SidebarHeaderProps = {
    accountConfig: any;
    cuentaSeleccionada: any;
    theme: any;
    handleNuevaConversacion: () => void;
    headerRef: any;
}

type SidebarFooterProps = {
    accountConfig: any;
    footerRef: any;
    usuario: any;
    handleLogout: any;

}
type ObserverType = IntersectionObserver;

/**
 * Sidebar Component
 *
 * Props:
 * - conversaciones: any[] - The conversations to be displayed.
 * - conversacionSeleccionada: string - The ID of the selected conversation.
 * - seleccionarConversacion: CallableFunction - Function to select a conversation.
 * - open: boolean - State to manage the opening and closing of the sidebar.
 * - handleDrawerToggle: () => void - Function to handle the opening and closing of the sidebar.
 *
 * This component is used to display a sidebar of conversations in the user interface.
 */
const Sidebar = (props: SidebarProps) => {

    // props y estados internos
    const {
        mostrarSwitcher,
        mostrarSidebar
    } = props;

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [scrollearAlPrincipio, setScrollearAlPrincipio] = useState(false);
    const drawerWidth = 350;
    const navigate = useNavigate();
    const theme = useTheme();

    const {
        isMobile,
        scrollHeight,
        scrollableHeight,
        headerRef,
        footerRef,
        scrollRef,
        calculateScrollableHeight,
        scrollToBottom,
        obtenerVariante,
        obtenerAnchor,
    } = useSidebarLayout();

    const {
        conversacionesAgrupadas,
        currentGroup,
        currentGroupRef,
        contarConversacionesAgrupadas,
        visibleGroups,
        loading,
        fetchMoreData,
        hasMore,
        isInitialLoad,
        obtenerGrupoPorEtiqueta,
        setCurrentGroup,
        orderedGroups,
        inicializarListado
    } = useSidebarList();

    // contextos de la aplicación
    const {usuario} = useAuthContext();

    const {
        conversaciones,
        conversacionSeleccionada,
        setConversacionSeleccionada,
        cuentaSeleccionada,
        idConversacion, setIdConversacion,
        accountConfig
    } = useAppContext();

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        navigate('/logout');
    };

    useEffect(() => {
        if (!cuentaSeleccionada || !usuario) {
            return;
        }

        inicializarListado();
    }, [cuentaSeleccionada, usuario]);

    if (!cuentaSeleccionada || !usuario) {
        return;
    }
    
    if (!mostrarSidebar) {
        return;
    }

    return (
        <div style={{order: accountConfig?.sidebar && accountConfig.sidebar.anchor === 'left' ? 1 : 2,}}>
            <Drawer
                variant={obtenerVariante()}
                open={open}
                anchor={obtenerAnchor()}
                onClose={handleDrawerToggle}
                style={{width: drawerWidth, padding: "5px 5px"}}
                className={"sidebar"}
                PaperProps={{
                    style: {
                        width: drawerWidth,
                        padding: "0",
                        backgroundColor: theme.palette.backgroundSidebar.main,
                        borderRight: "none",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        overflow: 'hidden', // Importante: deshabilita el scroll aquí

                    }
                }}

            >
                {obtenerVariante() === "temporary" && (
                    <IconButton
                        id="menuButton"
                        color="default"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        //sx={{marginLeft: "auto", display: {sm: 1}}}
                        sx={{
                            mt: 1, display: {sm: 1},
                            color: 'white',
                            position: 'fixed',
                            top: isMobile ? '1rem' : '3rem',
                            marginLeft: "0px",
                            right: obtenerAnchor() === "right" ? "350px" : 'auto', // Ajusta a tu gusto
                            left: obtenerAnchor() === "left" ? "350px" : 'auto',
                            borderRadius: '0',
                            backgroundColor: 'black',
                            height: '1.5em',
                            width: '1.5em'
                        }}
                    >
                        <MenuOpenIcon style={{transform: "scaleX(-1)"}}/>
                    </IconButton>
                )}

                <Box display="flex" flexDirection="column" height="100%" width="100%"
                     style={{
                         backgroundColor: theme.palette.backgroundSidebar.main,
                         padding: "0px 0px",
                         overflowY: "auto"
                     }}
                >

                    <Helmet>
                        <title>{cuentaSeleccionada.html_title}</title>
                        {cuentaSeleccionada.favicon_logo && (
                            <link rel="icon"
                                  href={"/images/" + cuentaSeleccionada.favicon_logo}/>
                        )}
                        <meta
                            name="description"
                            content="-"
                        />
                    </Helmet>

                    <SidebarHeader
                        accountConfig={accountConfig}
                        cuentaSeleccionada={cuentaSeleccionada}
                        theme={theme}
                        handleNuevaConversacion={() => {
                            setConversacionSeleccionada(null);
                            setIdConversacion(null);
                            navigate('/nueva-conversacion');
                            setScrollearAlPrincipio(true);
                            handleDrawerToggle();

                        }}
                        headerRef={headerRef}
                    />


                    <SidebarConversationsList
                        scrollRef={scrollRef}
                        scrollHeight={scrollHeight}
                        currentGroup={currentGroup}
                        conversaciones={conversaciones}
                        fetchMoreData={fetchMoreData}
                        hasMore={hasMore}
                        conversacionSeleccionada={conversacionSeleccionada}
                        setConversacionSeleccionada={setConversacionSeleccionada}
                        contarConversacionesAgrupadas={contarConversacionesAgrupadas}
                        conversacionesAgrupadas={conversacionesAgrupadas}
                        obtenerGrupoPorEtiqueta={obtenerGrupoPorEtiqueta}
                        isLoading={loading}
                        navigate={navigate}
                        currentGroupRef={currentGroupRef}
                        setCurrentGroup={setCurrentGroup}
                        orderedGroups={orderedGroups}
                        scrollearAlPrincipio={scrollearAlPrincipio}
                        setScrollearAlPrincipio={setScrollearAlPrincipio}
                        theme={theme}/>

                    <SidebarFooter
                        accountConfig={accountConfig}
                        footerRef={footerRef}
                        usuario={usuario}
                        handleLogout={handleLogout}/>

                </Box>

            </Drawer>

            {obtenerVariante() === "temporary" && (
                <IconButton
                    color="default"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{mt: 1, display: {sm: 1}}}
                    style={{
                        color: 'white',
                        position: 'fixed', // Cambia 'relative' a 'fixed'
                        top: isMobile ? '1rem' : '3rem',
                        right: obtenerAnchor() === "right" ? 0 : 'auto', // Ajusta a tu gusto
                        left: obtenerAnchor() === "left" ? 0 : 'auto',
                        borderRadius: '0',
                        backgroundColor: 'black',
                        height: '1.5em',
                        width: '1.5em',
                        marginLeft: obtenerAnchor() === "right" ? "20px" : 0, // Ajusta a tu gusto
                    }}
                >
                    <MenuIcon/>
                </IconButton>
            )}
        </div>
    );
}


const SidebarHeader = (props: SidebarHeaderProps) => {
    const {accountConfig, cuentaSeleccionada, theme, handleNuevaConversacion, headerRef} = props;
    return (
        <Box style={{marginTop: '1em'}} ref={headerRef}>
            {(accountConfig && accountConfig.sidebar && accountConfig.sidebar.nuevoLayout) ? (

                <Grid container spacing={2} alignItems="center" marginBottom={2}>
                    <Grid item sm={9}>
                        <Box display="flex" justifyContent="flex-start">
                            {cuentaSeleccionada.logo && (
                                <img
                                    src={"/images/" + cuentaSeleccionada.logo}
                                    style={{
                                        maxWidth: "100px",
                                        height: "auto",
                                        marginLeft: "20px"
                                    }} // Reduced from 100% to 70%
                                    alt="Logo"
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={3}>
                        <Box display="flex" justifyContent="flex-end" style={{marginRight: "20px"}}>
                            <IconButton
                                color="primary"
                                title={"Nueva conversación"}
                                aria-label="nueva conversación"
                                onClick={handleNuevaConversacion}
                            >
                                <AddComment/>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <Box>
                    {cuentaSeleccionada.logo && (
                        <Box textAlign={"center"}>
                            <img src={"/images/" + cuentaSeleccionada.logo} style={{maxWidth: "50%"}}/>
                        </Box>
                    )}

                    <Box textAlign={"center"} style={{padding: "0 15px"}}>
                        <Button
                            fullWidth
                            endIcon={<FontAwesomeIcon icon={faPlus}/>}
                            sx={{
                                margin: "10px 0",
                                padding: "10px 5px",
                                backgroundColor: theme.palette.primary.main,
                                color: "white",
                                fontSize: 16,
                            }}
                            variant={"contained"}
                            onClick={handleNuevaConversacion}
                        >
                            Nueva conversación
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    )
}


const SidebarFooter = (props: SidebarFooterProps) => {
    const {accountConfig, footerRef, usuario, handleLogout} = props;
    return (
        <Box ref={footerRef}>
            {accountConfig.sidebar.mostrarUsuario && (
                <PanelUsuario usuario={usuario} handleLogout={handleLogout} accountConfig={accountConfig}/>
            )}
        </Box>
    )
}

export default Sidebar