// useFetchAPI.ts
import {useCallback, useContext, useState} from 'react';
import {useAppContext} from "./useAppContext";
import ApiError from "../models/ApiError";
import {useAuthContext} from "../contexts/AuthContext";
import { CLAVE_TOKEN_JWT } from "../services/authService";

const useFetchAPI = () => {

    const { tokenSistema, tokenSistemaValido, setTokenSistemaValido } = useAuthContext();

    const {
        apiAccesible, setApiAccesible
    } = useAppContext();


    const fetchAPI = useCallback(async (endpoint: string, method: string = 'GET', body?: any, useToken: boolean = true, defaultHeaders = {}, appendUrl = true) => {
        let fallbackToken = null;
        let tokenAutenticacion = tokenSistema;
        if (useToken && !tokenSistema) {
            fallbackToken = window.localStorage.getItem(CLAVE_TOKEN_JWT);
            // console.log("Fallback token:", fallbackToken);
            if (!fallbackToken) {
                return false;
            }

            tokenAutenticacion = fallbackToken;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const headers: HeadersInit = useToken ? {
            'Authorization': `Bearer ${tokenAutenticacion}`,
            //'Content-Type': 'application/json',
        } : {};

        Object.assign(headers, defaultHeaders);

        const config: RequestInit = {
            method,
            headers,
            credentials: 'include',
            ...(body && { body: body }),
        };

        try {
            let fullApiUrl = appendUrl ? apiUrl + endpoint : endpoint;
            const response = await fetch(fullApiUrl, config);
            const data = await response.json();

            // Revisa si el status es "error"
            if (!response.ok) {
                // console.log(data);
                if (data && data.status === "error") {
                    // add data.payload if exists
                    throw new ApiError(data.message, response.status, data.payload);
                } else {
                    throw new ApiError(`API request failed: ${response.statusText}`, response.status);
                }
            }

            return data;
        } catch (error) {
            // si el error tiene que ver con autenticacion y que falla la autenticacion lo capturo aca
            // ya que puedo gestionarlo a nivel app integral.
            if (error.statusCode === 401) {
                // @todo: tengo que vaciar el token y mostrar pantalla de error
                if (error.message == "Token expirado.") {
                    window.localStorage.removeItem("llm_token");
                    return null;
                }
                
            } else if (error.statusCode === 403) {
                // no tenes permisos para acceder a este recurso
                // quizas puede estar accediendo a una conversacion a la que no tiene acceso
                // no hay que eliminarle el token necesariamente
                // window.localStorage.removeItem("llm_token");
                let message1 = !error.message ? "Token inválido." : error.message;
                throw new ApiError(message1, 403, {});
                // catch the type error
            } else if (error instanceof TypeError) {
                let message = error.message;
                if (message == "Failed to fetch") {
                    message = "No pudimos conectarnos con el servidor. Volvé a intentarlo..";
                }

                throw new ApiError(message, 500, {});
            }


            // console.log(error);
            throw error;
            return null; // Considera devolver null o lanzar el error dependiendo de tu enfoque de manejo de errores
        }
    }, [tokenSistema]);

    return fetchAPI;
};

export default useFetchAPI;
