import {useState, useCallback, useContext} from 'react';
import {useAppContext} from "hooks/useAppContext";
import {useConversacionContext} from "hooks/useConversacionContext";
import {MessageDto} from "models/MessageDto";
import useFetchApi from "../useFetchApi";
import {Conversacion} from "types/Conversacion";
import moment from "moment-timezone";

/**
 * Hook encargado de manejar el consumo y procesamiento de mensajes existentes una conversacion
 */
const useMensajes = () => {

    const {
        mensajes, setMensajes, agregarMensaje,
        mensajesAgrupados, setMensajesAgrupados,
        hayMasPaginas, setHayMasPaginas,
        isMessageWaiting, setIsMessageWaiting,
        ultimoMessageId, setUltimoMessageId,
        cargoLista, setCargoLista,
        setMensajeEnConstruccion,
        nuevoMensajeArchivos, setNuevoMensajeArchivos,
        setIsWaiting,
        nuevoMensajeInput, setNuevoMensajeInput,
        ultimoMensajeInput, setUltimoMensajeInput,
        nuevoMensajeRunStepError,
        setInputError,
        mensajeError, setMensajeError,
        nuevoMensajeAdjuntos, setNuevoMensajeAdjuntos,
        setUltimoMensajeAdjuntos
    } = useConversacionContext();

    const { setErrorSistema } = useAppContext();

    const fetchAPI = useFetchApi();
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const {
        setApiAccesible,
        cuentaSeleccionada,
        conversacionSeleccionada,
        setConversacionSeleccionada,
        setIdConversacion,
        conversaciones,
        setConversaciones,
        debug,
        setDebugInfo
    } = useAppContext();

    /**
     * Helper encargado de crear un nuevo modelo MessageDto
     * @param id
     * @param content
     * @param isUser
     * @param files
     */
    const createNewMessage = (id: string, content: string, isUser: boolean, files?: string[], fecha_creacion?: string, esFavorito?: boolean, valoracion?: number, fue_cancelado?: boolean) => {
        const newMessage = new MessageDto(id, isUser, content, files, fecha_creacion, esFavorito, valoracion, fue_cancelado);
        return newMessage;
    };

    /**
     * Busca los mensajes de una conversacion en la api
     */
    const obtenerMensajesApi = useCallback(async (conversationId, lastMessageId = null, refresh = false, soloConversacion = false) => {
        let url = `/conversations/${conversationId}`;
        let params = [];
        if (lastMessageId) {
            params.push('last_message_id=' + lastMessageId);
        }

        if (debug) {
            params.push('debug=1');
        }

        if (soloConversacion) {
            params.push('solo_conversacion=1');
        } else {
            if (refresh) {
                params.push('refresh=1');
            }
        }


        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        let data = await fetchAPI(url, "GET")

        if (debug) {
            setDebugInfo((prevDebugInfo) => {
                let debugData = data["debug"];
                // debugData["id"] = conversationId;
                // debugData["conversacion_seleccionada"] = conversacionSeleccionada;

                return {
                    ...prevDebugInfo,
                    "conversacion": debugData
                }
            });
        }
        return data;
    }, [debug]);

    /**
     * Funcion encargada de cargar los mensajes de una conversacion.
     * Puede recibir el ultimo id de mensaje para poder paginar los resultados
     */
    const cargarMensajes = useCallback(async (conversacionId, lastMessageId = null, reset = false) => {
        //console.log("useMensajes - cargarMensajes");
        let _mensajes = [];
        let data = null;

        try {
            let actualizarEnMensajeInicial = false;
            // if query param refresh is true, we need to update the initial message
            if (new URLSearchParams(window.location.search).get('refresh') === 'true') {
                actualizarEnMensajeInicial = true;
            }

            data = await obtenerMensajesApi(conversacionId, lastMessageId, actualizarEnMensajeInicial);
            // console.log("data", data);
        } catch (error) {
            console.error("Error al obtener mensajes:", error);
            setErrorSistema(error.message);
            return false;
        }

        if (!data) {
            return false;
        }

        // @todo: revisar si esto esta ok
        if (data["messages"] && data["messages"].length === 0) {
            setHayMasPaginas(false);
            setIsMessageWaiting(false);
            return;
        }

        let mensajesProcesados = [];
        let ultimoMensajeId = null;

        for (let i = 0; i < data["messages"].length; i++) {
            if (!ultimoMensajeId && data["messages"][i]["rol"] !== "User") {
                continue;
            } else if (!ultimoMensajeId && data["messages"][i]["rol"] === "User") {
                ultimoMensajeId = data["messages"][i]["id"];
            }

            mensajesProcesados.push(createNewMessage(
                data["messages"][i]["id"],
                data["messages"][i]["contenido"],
                data["messages"][i]["rol"] !== "Assistant",
                data["messages"][i]["archivos"],
                data["messages"][i]["fecha_creacion"],
                data["messages"][i]["es_favorito"],
                data["messages"][i]["valoracion"],
                data["messages"][i]["fue_cancelado"]
            ));
        }

        if (!reset) {
            mensajesProcesados = [...mensajesProcesados, ...mensajes];
        }

        setUltimoMessageId(ultimoMensajeId);
        setMensajes(mensajesProcesados);
        setHayMasPaginas(data["messages"].length > 5 && data["has_more_messages"]);
        setIsMessageWaiting(false);
        setIsWaiting(false);


    }, [cuentaSeleccionada, conversacionSeleccionada, conversaciones]);

    /**
     * Metodo encargado de agrupar los mensajes en base al rol del que lo envia
     */
    const agruparMensajes = useCallback(() => {
        //console.log("useMensajes - agruparMensajes");
        if (mensajes.length === 0) {
            setMensajesAgrupados([]);
            return;
        }

        let lastRole = null;
        let currentGroup = [];
        const groupedMessages = [];

        mensajes.forEach((message) => {
            if (message.isUser === lastRole) {
                // Si el rol del mensaje actual es el mismo que el del último rol procesado,
                // agregar el mensaje al grupo actual
                currentGroup.push(message);
            } else {
                // Si el rol del mensaje actual es diferente al último rol procesado,
                // iniciar un nuevo grupo de mensajes y actualizar el último rol procesado
                if (currentGroup.length > 0) {
                    groupedMessages.push(currentGroup);
                }
                currentGroup = [message];
                lastRole = message.isUser;
            }
        });

        // Asegurarse de agregar el último grupo de mensajes
        if (currentGroup.length > 0) {
            groupedMessages.push(currentGroup);
        }

        // console.log(groupedMessages);
        setMensajesAgrupados(groupedMessages);
    }, [mensajes]);

    /**
     * Se encarga de traer los ultimos mensajes del asistente para agregarlos a la lista de mensajes
     * @param conversacionId
     * @param eliminarUltimo
     */
    const refrescarListaDeMensajes = useCallback(async (conversacionId, eliminarUltimo = false, nuevaConversacion = false) => {
        const refresh = eliminarUltimo;
        let data = await obtenerMensajesApi(conversacionId, null, refresh); // eliminar ultimo es sinonimo de refresh
        setNuevoMensajeArchivos([])
        setIsWaiting(false);

        // actualizo los ultimos mensajes mientras sean del asistente
        let maxIndex = null;
        if (!data || !data["messages"]) {
            return;
        }

        for (let i = data["messages"].length - 1; i >= 0; i--) {
            if (data["messages"][i]["rol"] !== "Assistant") {
                maxIndex = i;
                break;
            }
        }

        let limit = data["messages"].length;

        if (eliminarUltimo) {
            if (!nuevoMensajeRunStepError) {
                setMensajeEnConstruccion(null);
            }
        }

        // agrego la conversacion al menu de conversaciones
        console.log("nueva conversacion", nuevaConversacion);
        if (nuevaConversacion) {
            let conversacionesCopy = [...conversaciones];
            let fecha_creacion = new Date();
            // add 5 hours to the date to make it appear in the future
            fecha_creacion.setHours(fecha_creacion.getHours() + 5);
            // set fecha_ultimo_mensaje as string in this format 2024-08-09 12:44:48

            let fecha_ultimo_mensaje = moment(fecha_creacion).format("YYYY-MM-DD HH:mm:ss");

            const nuevaConversacion: Conversacion = {
                activo: true,
                assistant_id: "",
                es_prueba: false,
                id: conversacionId,
                titulo: data["conversation"]["titulo"],
                fecha_creacion: fecha_creacion.toUTCString(),
                fecha_ultimo_mensaje: fecha_ultimo_mensaje
            };

            console.log("fecha ultimo mensaje", nuevaConversacion.fecha_ultimo_mensaje);
            // append nueva conversacion a conversaciones at the beginning
            if (Array.isArray(conversaciones)) {
                setConversaciones([nuevaConversacion, ...conversaciones]);
            } else {
                setConversaciones([nuevaConversacion]);
            }

        }

        for (let i = maxIndex + 1; i < limit; i++) {
            setMensajes((prevMensajes) => [...prevMensajes, createNewMessage(
                data["messages"][i]["id"],
                data["messages"][i]["contenido"],
                data["messages"][i]["rol"] === "User",
                data["messages"][i]["archivos"],
                data["messages"][i]["fecha_creacion"],
                data["messages"][i]["es_favorito"],
                data["messages"][i]["valoracion"],
                data["messages"][i]["fue_cancelado"]
            )]);
        }

    }, [mensajes, nuevoMensajeRunStepError, conversaciones, setConversaciones]);

    const iniciarNuevaConversacion = useCallback(async () => {
        try {
            const data = await fetchAPI(`/conversations/start/${cuentaSeleccionada.id}`);
            if (!data || !data.conversation_id) {
                throw new Error("No se pudo crear una nueva conversación");
            }
            return data.conversation_id;
        } catch (error) {
            // console.log("creo mensaje error", error.message);
            setMensajeError(error.message);
            // setErrorSistema(error.message);
            return null;
        }
    }, [cuentaSeleccionada, setMensajeError]);


    /**
     * Envia el mensaje. Se le puede pasar el input del string pero sino utilizará el state del contexto.
     * El soporte es para poder utilizarlo en acciones como iniciar pregunta.
     */
    const enviarMensaje = useCallback(async (conversationId: string = null, mensaje: string = null, agregarMensaje: boolean = true, testMode: boolean = false) => {
        const primerMensaje = mensajes.length === 0;
        let conversacionCreada = {}

        // mecanismo fallback para levantarlo de la variable de estado
        if (!mensaje) {
            mensaje = nuevoMensajeInput;
        }

        // limpio y si no valida genero el shake y muestro un error
        if (mensaje.trim() === "") {
            setInputError(true);
            setTimeout(() => setInputError(false), 2000); // Remueve el error después de 2 segundos
            setIsWaiting(false);
            return false;
        }

        // limpio el input
        setUltimoMensajeInput(mensaje);
        setUltimoMensajeAdjuntos(nuevoMensajeAdjuntos);
        setNuevoMensajeInput("");
        setNuevoMensajeAdjuntos(() => []);

        if (nuevoMensajeAdjuntos.length > 0) {
            mensaje = procesarMensajeConAdjuntos(mensaje, nuevoMensajeAdjuntos);
            // console.log("mensaje con adjuntos", mensaje);
        }

        // activo el loader
        setIsWaiting(true);

        // agrego un nuevo mensaje del usuario
        if (agregarMensaje) {
            setMensajes((prevMensajes) => [...prevMensajes, crearNuevoMensajeUsuario(mensaje)]);
        }

        // creo el mensaje en construccion
        let mensajeConstruccion = createNewMessage("xxxx-xxxxx", "", false, []);
        setMensajeEnConstruccion(mensajeConstruccion);

        // envio el mensaje y le agrego si es primer mensaje
        let conversacionId = conversationId ? conversationId : conversacionSeleccionada.id;

        let url = !testMode
            ? `/conversations/${conversacionId}/send-message`
            : `/conversations/${conversacionId}/send-message-test`

        if (primerMensaje) {
            url += "?first_message=1";
        }

        // creo el formdata y asigno valores
        const formData = construirBodyFormulario(mensaje, nuevoMensajeArchivos);

        try {
            let metodo = 'POST';
            const data = await fetchAPI(url, metodo, formData);
            // console.log("llegue a data", data);
            if (!data || !data.message) {
                return;
            }
            //console.log(data);

            if (testMode) {
                return;
            }

            // inicio una llamada para ver el estado del mensaje
            const userMessageId = data.message.id;
            actualizarIdUltimoMensajeUsuario(userMessageId);

            // check if run is inside data and if have an id
            if (!data.run || !data.run.id) {
                setIsWaiting(false)
                return;
            }

            return data.message.id;
        } catch (error) {
            setMensajeError(error.message)
            // alert(error.message);
        } finally {
            setIsWaiting(false);
        }

        return null;
    }, [cuentaSeleccionada, conversacionSeleccionada, mensajes, nuevoMensajeInput]);

    const procesarMensajeConAdjuntos = (mensaje, adjuntos): string => {
        let nuevoMensaje = mensaje;
        // primero tengo que agrupar los adjuntos por tipo
        // una vez que los tengo agrupados tengo que generar las instrucciones para uno o multiples pasandole el mensaje
        // luego devuelvo el mensaje procesado por las instrucciones
        // quizas podemos pasar un parametro al obtener texto donde dice si es el primero o no y en base a eso las agrega
        let adjuntosAgrupadosPorTipo = adjuntos.reduce((acc, cur) => {
            const tipo = cur.tipo;
            if (!acc[tipo]) {
                acc[tipo] = [];
            }
            acc[tipo].push(cur);
            return acc;
        }, {});

        console.log("adjuntosAgrupadosPorTipo", adjuntosAgrupadosPorTipo);


        // ahora tenemos que procesar cada tipo de adjunto
        for (const tipo in adjuntosAgrupadosPorTipo) {
            const tipoAdjuntos = adjuntosAgrupadosPorTipo[tipo];
            let outputAdjuntos = "";
            console.log("tipoAdjuntos", tipoAdjuntos);
            for (let i = 0; i < tipoAdjuntos.length; i++) {
                const adjunto = tipoAdjuntos[i];
                console.log("adjunto", adjunto);
                outputAdjuntos += adjunto.obtenerInstruccionesAdjunto(outputAdjuntos, i === 0);
            }
            nuevoMensaje += "\n"+outputAdjuntos;
        }

        return nuevoMensaje;

    }

    /**
     * Metodo para generar un nuevo mensaje del usuario. Carga la fecha de creación.
     * @param mensaje
     */
    const crearNuevoMensajeUsuario = (mensaje) => {
        const timestamp = Math.floor(Date.now() / 1000);
        let fecha_creacion = timestamp.toString();

        return createNewMessage("xxx-new", mensaje, true, [], fecha_creacion);
    }

    /**
     * Genera el body del formulario para enviar un mensaje
     * @param mensaje
     * @param archivos
     */
    const construirBodyFormulario = (mensaje, archivos) => {
        let formData = new FormData();
        formData.append('message', mensaje);

        // agrego los archivos del dropzone
        archivos.forEach((file, index) => {
            formData.append(`file_${index + 1}`, file);
        });

        return formData;
    }

    const actualizarIdUltimoMensajeUsuario = (nuevoId) => {
        setMensajes((prevMensajes) => {
            const messageIndex = prevMensajes.findIndex(message => message.id === "xxx-new");
            if (messageIndex >= 0) {
                const updatedMessages = [...prevMensajes];
                updatedMessages[messageIndex] = {
                    ...updatedMessages[messageIndex],
                    id: nuevoId
                };
                return updatedMessages;
            }
            return prevMensajes;
        });
    };

    return {
        mensajes, setMensajes,
        isLoading, hasMore,
        cargarMensajes,
        agruparMensajes,
        refrescarListaDeMensajes,
        createNewMessage,
        enviarMensaje, iniciarNuevaConversacion,
        obtenerMensajesApi
    };
};

export default useMensajes;
