import {useEffect, useMemo, useState, useCallback} from 'react';
import io from 'socket.io-client';
import {useConversacionContext} from "../useConversacionContext";
import {MessageDto} from "../../models/MessageDto";
import { useAppContext } from "hooks/useAppContext";
import { CLAVE_TOKEN_JWT} from "../../services/authService";

const useSocket = () => {

    const { addNotification } = useAppContext();

    const {
        mensajeEnConstruccion, setMensajeEnConstruccion,
        actualizarMensajeEnConstruccion, actualizarArchivosMensajeEnConstruccion,
        setNuevoMensajeToolCall, setNuevoMensajeRunStepError,
        setNuevoMensajeRunId, nuevoMensajeRunId,
        setNuevoMensajeToolInfo, nuevoMensajeToolInfo
    } = useConversacionContext();

    const miSocketClientId = useMemo(() => {
        return window.localStorage.getItem(CLAVE_TOKEN_JWT);
    }, []);

    const socket = useMemo(() => {
        if (typeof window !== 'undefined') {
            const existingSocket = (window as any).__socketInstance;
            if (existingSocket) {
                existingSocket.connect();
                return existingSocket;
            }

            const apiUrl = process.env.REACT_APP_SOCKET_IO_URL;
            const newSocket = io(apiUrl, {
                query: {
                    clientId: miSocketClientId
                }
            });

            (window as any).__socketInstance = newSocket;
            return newSocket;
        }
        return null;
    }, []);

    const [socketConectado, setSocketConectado] = useState(socket ? socket.connected : false);

    const cancelarRun = useCallback(() => {
        if (!nuevoMensajeRunId) {
            console.log("no hay run id para cancelar");
            return;
        }

        socket.emit('cancelar_run', { run_id: nuevoMensajeRunId });
        setNuevoMensajeToolCall(0);
        setNuevoMensajeRunId(null);
        addNotification("Se canceló la ejecución correctamente", "success");

    }, [nuevoMensajeRunId, socket, setNuevoMensajeToolCall]);

    useEffect(() => {
        const socketInstance = socket;
        const eventHandlers = {
            connect: () => {
                //console.log("conectado");
                socket.emit('join_room', { clientId: miSocketClientId });
                setSocketConectado(true);
            },
            disconnect: () => {
                //console.log("desconectado");
                setSocketConectado(false);
            },
            message: (data) => {
                // console.log("on_message", data);
            },
            on_event: (data) => {
                //console.log("socketio: evento disparado", data.event);
                // if (data.event === "thread.run.completed") {
                //     setNuevoMensajeToolCall(0);
                // }
            },
            on_run_step_created: (value) => {
                // console.log("socketio: run step created", value);

                // guardamos el id del run por si hay que cancelarlo
                setNuevoMensajeRunId(value.run_id);

                // cuando vuelvo a correr un paso limpio el error
                setTimeout(() => {setNuevoMensajeRunStepError(false);}, 1000);

                if (value.type === "tool_calls") {
                    console.log("socketio: tool call on_run_step_created", value);
                    setNuevoMensajeToolCall(1);
                }
            },
            on_run_step_done: (value) => {
                console.log("socketio: run step done", value);
                if (value.type === "tool_calls") {
                    setNuevoMensajeToolCall(0);
                    setNuevoMensajeToolInfo(null);
                }

                if (value.status === "failed") {
                    //alert("hubo un problema con openai");
                    // console.log(value.last_error);
                    setNuevoMensajeRunStepError(value.last_error.message)
                }
            },
            on_message_created: (value) => {
                // console.log("socketio: message created", value);
                // creo el mensaje en construccion
                // prueba para testear el stream
                //let nuevoMensaje = createNewMessage("xxxx-xxxxx", "", false, []);
                //setMensajeEnConstruccion(nuevoMensaje);
            },
            on_message_done: (value) => {
                // console.log("socketio: message done", value);
                // if (value.content) {

                // }

                // agrego dos espacios para mensaje y mensajes
                actualizarMensajeEnConstruccion({"data": "\n\n"});
                setTimeout(() => {setNuevoMensajeRunStepError(false);}, 1000);
            },
            on_text_delta: (value) => {
                //console.log("socketio: on_text_delta", value);
                actualizarMensajeEnConstruccion(value);
            },
            on_image_delta: (value) => {
                //console.log("socketio: on_image_delta", value);
            },
            on_image_file_done: (value) => {
                //console.log("socketio: on_image_file_done", value);
                //actualizarArchivosMensajeEnConstruccion(value);
            },
            on_tool_call_created: (value) => {
                console.log("socketio: tool call created", value);
                setNuevoMensajeToolInfo(value);

            },
            on_tool_call_delta: (value) => {
                // console.log("socketio: tool call delta", value);
                // por ahora aca no hago nada
            },
            on_tool_call_done: (value) => {
                // console.log("socketio: tool call done", value);

            },
            finish: (value) => {
                // console.log(value);
                setNuevoMensajeRunId(null);
                // refrescarListaDeMensajes(conversationId);
                //setMensajeEnConstruccion(null);
            }
        };

        Object.entries(eventHandlers).forEach(([event, handler]) => {
            socket.on(event, handler);
        });

        return () => {
            Object.keys(eventHandlers).forEach((event) => {
                socket.off(event, eventHandlers[event]);
            });

            if (socket) {
                socket.disconnect();
                if (typeof window !== 'undefined') {
                    delete (window as any).__socketInstance;
                }
            }
        };
    }, []);

    return { socket, socketConectado, cancelarRun };
};

export default useSocket;