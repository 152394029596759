import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    TextField,
    Typography,
    Container,
    Paper,
    Alert,
} from '@mui/material';

import { useAppContext } from "hooks/useAppContext";
import { useAuthContext } from "contexts/AuthContext";
import { authService } from 'services/authService'; // Asegúrate de importar authService

interface LoginErrors {
    username?: string;
    password?: string;
    general?: string;
}

const LoginPanel: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<LoginErrors>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { cuentaSeleccionada } = useAppContext();
    const navigate = useNavigate();
    const { usuario, setUsuario, setTokenSistema, setTokenSistemaValido } = useAuthContext();
    const {setTimezone} = useAppContext();

    const validateForm = (): boolean => {
        let tempErrors: LoginErrors = {};
        tempErrors.username = username ? "" : "El nombre de usuario es requerido";
        tempErrors.password = password ? "" : "La contraseña es requerida";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            try {
                const token = await authService.autenticarConCredenciales(username, password);
                // Si llegamos aquí, tenemos un token de sistema (ya sea nuevo o existente)
                setTokenSistema(token);
                // Asumiendo que tienes un método para obtener la información del usuario
                // Intentamos obtener la información del usuario
                const { usuario, timezone } = await authService.obtenerInfoUsuario();
                if (usuario) {
                    // console.log("Usuario:", usuario);
                    setUsuario(usuario);
                    setTimezone(timezone);
                    setTokenSistemaValido(true);
                    navigate("/");
                    // console.log("Usuario autenticado:", usuario);
                } else {
                    throw new Error("No se pudo obtener la información del usuario");
                }
            } catch (error) {
                console.error('Error durante la autenticación:', error);
                setErrors({ ...errors, general: "Error de autenticación. Por favor, intente nuevamente." });
            } finally {
                setIsLoading(false);
            }
        } else {
            // console.log('Formulario inválido');
        }
    };

    useEffect(() => {
        if (usuario) {
            navigate("/");
        }
    }, [usuario]);

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} sx={{ marginTop: 8, padding: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* Logo */}
                    <Box sx={{ width: '100%', height: 100, mb: 5 }}>
                        {cuentaSeleccionada.logo && (
                            <Box textAlign="center" sx={{mx: 2, my: 2}}>
                                <img src={"/images/" + cuentaSeleccionada.logo} style={{maxWidth: "70%"}} alt="Logo" />
                            </Box>
                        )}
                    </Box>

                    <Typography component="h1" variant="h5" mt={5}>
                        Iniciar sesión
                    </Typography>
                    {errors.general && <Alert severity="error" sx={{ mt: 2, width: '100%' }}>{errors.general}</Alert>}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Nombre de usuario"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                            error={!!errors.username}
                            helperText={errors.username}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default LoginPanel;