// src/components/Message.tsx
import React, {Fragment, useEffect, useState, useMemo, useRef} from "react";
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import {MessageDto} from "../models/MessageDto";
import MarkdownIt from 'markdown-it';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faDownload} from '@fortawesome/free-solid-svg-icons';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faRobot} from '@fortawesome/free-solid-svg-icons';
import {Box, Typography, Paper, Chip} from '@mui/material';
import Modal from '@mui/material/Modal';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css'; // O el tema que prefieras
import Loader from "./Loader";
import "./Message.css";
import {Link} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material/styles';
import MateriaCard from "./MateriaCard";
import FilaArchivo from "./FilaArchivo";
import {useConversacionContext} from "../hooks/useConversacionContext";
import MessageFilesRow from "./MessageFilesRow";
import ControladorComponenteDinamicoLLM from "./mensajes/ControladorComponenteDinamicoLLM";

interface MessageProps {
    message: MessageDto;
    lastMessage?: MessageDto;
    isLastMessage?: boolean;
    conversationId: string;
    isBuildingPanel?: boolean;
}

const md = new MarkdownIt();
// md.renderer.rules.image = function (tokens, idx, options, env, self) {
//     const token = tokens[idx];
//     const src = token.attrs[token.attrIndex('src')][1];
//     const alt = token.content;
//
//     return `<img data-src="${src}" alt="${alt}" class="lazyload" />`;
// };

/**
 * Message Component
 *
 * Props:
 * - message: MessageDto - The message object to be displayed.
 * - lastMessage: MessageDto - The last message object in the conversation.
 * - conversationId: string - The ID of the conversation the message belongs to.
 *
 * State:
 * - open: boolean - State to manage the opening and closing of the modal.
 * - parts: array - Array to store the parts of the message content.
 * - reemplaceArchivos: boolean - State to manage whether files should be replaced in the message content.
 * - infoMensajeLoading: boolean - State to manage the loading state of the message information.
 * - infoMensaje: any - State to store the information of the message.
 * - jsonObjects: array - Array to store JSON objects found in the message content.
 * - isOpen: boolean - State to manage the opening and closing of the accordion for file attachments.
 *
 * Methods:
 * - handleToggle: Function to toggle the opening and closing of the accordion for file attachments.
 * - handleOpen: Function to handle the opening of the modal and fetching of the message information.
 * - handleClose: Function to handle the closing of the modal.
 * - preprocessContent: Function to preprocess the content of the message.
 * - verPasosIntermedios: Function to view the intermediate steps of the message.
 */
const Message: React.FC<MessageProps> = ({message, conversationId, lastMessage, isLastMessage = true, isBuildingPanel = false}) => {

    const [open, setOpen] = React.useState(false);

    //const [parts, setParts] = React.useState([]);
    const [reemplaceArchivos, setReemplaceArchivos] = React.useState(false);
    const [infoMensajeLoading, setInfoMensajeLoading] = React.useState(false);
    const [infoMensaje, setInfoMensaje] = React.useState<any>(null);
    const [jsonObjects, setJsonObjects] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {isWaiting} = useConversacionContext();
    const [incompleteBlocks, setIncompleteBlocks] = useState<Set<number>>(new Set());

    const isJsonComplete = (jsonString: string) => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (e) {
            return false;
        }
    };


    // modal para imagenes
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const messageContainerRef = useRef(null); // Referencia al contenedor del mensaje


    const handleOpen = () => {
        if (!lastMessage) {
            return;
        }

        setInfoMensajeLoading(true);
        setOpen(true);

        let mensajes = [];
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = `${apiUrl}/conversations/${conversationId}/messages/${lastMessage.id}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setInfoMensaje(data);
                setInfoMensajeLoading(false);
            });

    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleModalOpen = (image: string) => {
        setModalImage(image);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const esImagen = (archivo) => /\.(jpg|jpeg|png|gif)$/.test(archivo);

    /**
     * Principal metodo para procesar el contenido de un mensaje.
     * Sus principales responsabilidades son:
     *  + detectar archivos de openai: interceptar el sandbox y reemplazarlo por la url de yais/s3 en el caso de que esté disponible
     *  + dividir el mensaje en partes: según bloques de código JSON para convertirlos en objetos JSON que levanten componentes React
     *  + poner loading en las imagenes: si el mensaje se está construyendo interceptar y poner un loader
     *
     * @param message
     * @param isWaiting
     */
    const renderizarContenido = (message: MessageDto, isWaiting) => {
        //console.log("preproceso el contenido del mensaje: ", message.id);
        let content = message.content;
        if (message.files && message.files.length > 0) {
            content = reemplazarLinksSandbox(content, message.files);
        }

        // Modificamos la expresión regular para capturar bloques JSON incluso si no están cerrados
        let regexParts = /(```json[\s\S]*?(?:```|$))/g;
        const bloques = content.split(regexParts);

        const newParts = bloques.map((bloque, index) => {
            // console.log("Procesando bloque:", bloque);
            if (bloque.trim().startsWith('```json')) {
                // console.log("Bloque JSON detectado");
                return parsearBloqueJson(bloque, index);
            } else {
                // console.log("Bloque Markdown");
                return parsearMarkdown(bloque, isBuildingPanel);
            }
        });

        // console.log("Partes procesadas:", newParts);
        return newParts;
    }

    /**
     * Parsea y genera el output en base a la configuración.
     * Puede renderear un objeto JSON en un componente MateriaCard. Que en este caso el unico objeto que tenemos configurado es Materia que no está siendo utilizado.
     * A veces el LLM agrega ... (tres puntos) para resumir en el medio del json, por eso agregamos la casuística para
     * resolverlo en el caso de que exista.
     * @param bloque
     */
    const parsearBloqueJson = (bloque: string, index: number) => {
        let jsonContent = bloque.replace(/```json|```/g, '').trim();
        if (!isJsonComplete(jsonContent)) {
            setIncompleteBlocks(prev => new Set(prev).add(index));
            return null;
        }

        setIncompleteBlocks(prev => {
            const newSet = new Set(prev);
            newSet.delete(index);
            return newSet;
        });
        // remove from jsonContent this type of elemenent "...\n"

        // console.log("part", part);
        // console.log("jsonContent", jsonContent);
        try {
            const jsonObject = JSON.parse(jsonContent);
            // console.log("jsonObject", jsonObject);

            if (typeof jsonObject === 'object' && jsonObject !== null){
                if (jsonObject.hasOwnProperty('yais_componente')) {
                    // console.log("devuelvo el objeto", jsonObject);
                    return jsonObject;
                }
            }

            // sino lo devuelvo como un json comun a parsear
            return parsearMarkdown(bloque, isBuildingPanel);

        } catch (error) {
            // Si hay un error, intentar corregirlo
            if (error instanceof SyntaxError) {
                // Dividir la cadena en un array usando "...\\n" como delimitador
                let partesPuntos = jsonContent.split('...');

                // Unir las partes en una cadena, sin nada entre ellas
                jsonContent = partesPuntos.join('');

                // Reemplazar las comas adicionales al final de los arrays y objetos
                let fixedJsonString = jsonContent.replace(/,(\s*]|\s*})/g, '$1');
                // console.log("fixedJsonString", fixedJsonString);

                // Intentar parsear el JSON corregido
                try {
                    const fixJsonObject = JSON.parse(fixedJsonString);
                    if (Array.isArray(fixJsonObject) && fixJsonObject.length > 0) {
                        // si el json tiene el campo yais_componente, entonces lo marco como un componente
                        if (fixJsonObject.hasOwnProperty('yais_componente')) {
                            return fixJsonObject;
                        }
                    }

                    return parsearMarkdown(bloque, isBuildingPanel);
                } catch (error) {
                    return parsearMarkdown(bloque, isBuildingPanel);
                    //console.error('No se pudo corregir el JSON:', error);
                }
            }
        }
    };

    /**
     * Parsea el markdown reemplazando elementos que no estén listos.
     * @param bloque
     * @param isBuildingPanel
     */
    const parsearMarkdown = (bloque, isBuildingPanel) => {
        let markdown_rendered = md.render(bloque).replace(/<p>(.*?)<\/p>/gs, (match, p1) => {
            return '<p>' + p1.replace(/\n/g, '<br>') + '</p>';
        });

        return isBuildingPanel ? removeSandboxImages(markdown_rendered) : markdown_rendered;
    }

    /**
     * En caso de que tengamos archivos en el mensaje reemplazamos los valores de sandbox por los mismos
     *
     * @param content
     * @param files
     */
    const reemplazarLinksSandbox = (content, files) => {
        // Expresión regular para buscar enlaces en Markdown que comienzan con "sandbox:"
        const regex = /\[.*?\]\((sandbox:.*|output\/.*|attachment:.*)\)/g;


        // Reemplazar todos los enlaces que comienzan con "sandbox:" con el primer archivo adjunto
        let fileIndex = 0;

        // Reemplazar todos los enlaces que comienzan con "sandbox:" con los archivos adjuntos
        return content.replace(regex, (match) => {
            // console.log("matchee 1");
            const archivo = files[fileIndex];
            let replacement;

            if (esImagen(archivo)) {
                // Si el archivo es una imagen, reemplazar con un enlace a la imagen
                replacement = `![Descargar archivo](${archivo})`;
            } else {
                // Si el archivo no es una imagen, reemplazar con un enlace de descarga
                replacement = `[Descargar archivo](${archivo})`;
            }
            fileIndex = (fileIndex + 1) % files.length; // Rotar el índice de los archivos
            return replacement;
        });
    }

    /**
     * Se encarga de intercepar cualquier imagen procesada de openai y reemplazarla por un loader
     * @param htmlString
     */
    const removeSandboxImages = (htmlString) => {
        // Crear un nuevo DOMParser
        const parser = new DOMParser();

        // Convertir el string HTML en un objeto DOM
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Obtener todas las imágenes del documento
        const images = doc.getElementsByTagName('img');

        // Convertir HTMLCollection a Array para poder usar el método reverse
        const imagesArray = Array.from(images).reverse();

        // Iterar sobre todas las imágenes
        imagesArray.forEach((img) => {
            // Si el src de la imagen contiene "sandbox", eliminar la imagen
            if (img.src.includes('sandbox') || img.src.includes('attachment')) {
                img.src = '/images/loader_gif.gif';
            }
        });

        // Devolver el HTML como string
        return doc.body.innerHTML;
    }

    const parts = useMemo(() => renderizarContenido(message, isWaiting), [message, isWaiting]);

    const verPasosIntermedios = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = `${apiUrl}/conversations/${conversationId}/runs/run_9QlJGgyTbPromcUWHGxCvPsE/steps`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
            });
    }

    const renderedParts = useMemo(() => {
        return parts.map((part, index) => {
            if (typeof part === 'string') {
                return (
                    <Box sx={{...theme.typography.body2}} key={"message_" + index}>
                        <div className={"parte"} dangerouslySetInnerHTML={{__html: part}} />
                    </Box>
                );
            } else {
                return (
                    <ControladorComponenteDinamicoLLM key={"componente_dinamico_" + index} cfgComponente={part} />
                );
            }
        });
    }, [parts, theme.typography.body2, incompleteBlocks]);

    useEffect(() => {
        const lazyImages = document.querySelectorAll('img.lazyload');
        if ('IntersectionObserver' in window) {
            const imageObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const image = entry.target as HTMLImageElement;
                        image.src = image.dataset.src;
                        image.classList.remove('lazyload');
                        imageObserver.unobserve(image);
                    }
                });
            });

            lazyImages.forEach(img => imageObserver.observe(img));
        } else {
            // Fallback para navegadores que no soportan IntersectionObserver
            lazyImages.forEach(img => {
                const image = img as HTMLImageElement;
                image.src = image.dataset.src;
            });
        }
    }, [renderedParts]);


    /**
     * useEffect hook para:
     * - agregar event listeners a las imágenes para que se abran en un modal y se puedan descargar
     * - resaltar el código según lenguaje en los bloques de código
     * - limpiar los event listeners cuando el componente se desmonte
     *
     */
    useEffect(() => {
        // si todavia esta cargando no hacemos nada
        if (isWaiting) {
            return;
        }

        const images = messageContainerRef.current?.getElementsByTagName('img');

        if (images) {
            // Para cada imagen, agregar un event listener que abra el modal cuando se haga clic en la imagen
            for (let i = 0; i < images.length; i++) {
                images[i].addEventListener('click', () => handleModalOpen(images[i].src));
            }
        }

        if (messageContainerRef.current) {
            messageContainerRef.current.querySelectorAll('.parte pre code').forEach((block) => {
                // @ts-ignore
                hljs.highlightElement(block);
            });
        }

        // Limpiar los event listeners cuando el componente se desmonte
        return () => {
            if (images) {
                for (let i = 0; i < images.length; i++) {
                    images[i].removeEventListener('click', () => handleModalOpen(images[i].src));
                }
            }
        };

    }, [parts, isWaiting]); // Ejecutar cada vez que el mensaje cambie


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: "left",
        }} id={"mensaje_" + message.id}>
            <Box className={"mensaje"} sx={{ flex: 1 }}  ref={messageContainerRef}>

                {renderedParts}

                {message.fueCancelado && isLastMessage && (
                    <Typography variant="body2" sx={{margin: "10px 0", paddingBottom: "10px", fontSize: '90%', color: 'red'}}>
                        Mensaje cancelado.
                    </Typography>
                )}

                {/*Ocultamos la fila con el detalle de los archivos por redundante.*/}
                {/*<MessageFilesRow files={message.files} isMobile={isMobile}/>*/}
            </Box>

            {modalImage && (

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    position: 'relative'
                }}>
                    <Box style={{position: 'relative'}}>
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'flex-end', // Alinea los íconos a la derecha
                            padding: '20px 10px', // Espacio alrededor del encabezado
                            backgroundColor: theme.palette.primary.main, // Fondo semi-transparente para el encabezado
                            borderRadius: '5px 5px 0 0', // Bordes redondeados solo en la parte superior
                        }}>
                            <a href={modalImage} download style={{
                                marginRight: '20px', // Espacio entre íconos
                                color: "#fff", // Color del ícono de descarga
                                zIndex: 1,
                            }} title={"Descargar imagen"}>
                                <FontAwesomeIcon icon={faDownload} size={"xl"}/>
                            </a>
                            <FontAwesomeIcon icon={faTimes} style={{
                                marginRight: '10px',
                                cursor: 'pointer',
                                color: "#fff",
                                zIndex: 1,
                            }} onClick={handleModalClose} size={"xl"}/>
                        </Box>
                        <img src={modalImage} alt="Imagen" style={{maxWidth: '100%', maxHeight: '85vh'}}/>
                    </Box>
                </Box>
            </Modal>
            )}
        </div>

    );
};

export default Message;