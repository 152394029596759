import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Chat from "./Chat";
import {useAppContext} from "../hooks/useAppContext";
import Loader from "./Loader";
import useSidebarList from "../hooks/useSidebarList";
import {Conversacion} from "../types/Conversacion";

type HomeProps = {
}

/**
 * Home Component
 *
 * Props:
 * - conversaciones: any[] - The conversations to be displayed.
 * - conversacionSeleccionada: string - The selected conversation.
 * - seleccionarConversacion: CallableFunction - Function to select a conversation.
 *
 * Methods:
 * - useEffect: React Hook to perform side effects in function components.
 * - navigate: Function to navigate to a different route.
 */
const Home = (props: HomeProps)  => {

    const { conversaciones, asistenteSeleccionado, cuentaSeleccionada } = useAppContext();
    const { loading } = useSidebarList();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("iniciamos home", loading);
        if (loading) {
            return;
        }

        if (!conversaciones) {
            console.log("no se cargaron las conversaciones");
            return;
        }

        if (conversaciones.length === 0) {
            console.log("no hay conversaciones ya cargadas");
            navigate("/nueva-conversacion");
            return;
        }

        // chequear el tiempo que paso desde la ultima conversación
        let ultimaConversacion:Conversacion = conversaciones[0];
        if (ultimaConversacion.fecha_ultimo_mensaje) {
            let fechaUltimaConversacion = new Date(ultimaConversacion.fecha_ultimo_mensaje);
            let ahora = new Date();
            let diferencia = ahora.getTime() - fechaUltimaConversacion.getTime();
            let tiempoEnSegundos = 1000 * 60 * 60 * 7; // 1 hora en segundos
            if (diferencia > tiempoEnSegundos) {
                navigate("/nueva-conversacion");
                return;
            }
        }

        navigate("/ver-conversacion/"+conversaciones[0]["id"]);
    }, [loading, cuentaSeleccionada, conversaciones]);

    return (
        //Por alguna razon si borro este componente entero o retorno null no funciona y se rompe el backend, revisar luego
        <Loader />

    )
}

export default Home;
