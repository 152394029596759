// src/components/Chat.tsx
import React, {useEffect, useState, useRef, useMemo, useCallback} from "react";
import {
    Box,
    TextField,
    Button,
    Container,
    Grid,
    LinearProgress,
    CircularProgress,
    InputAdornment,
    Typography, Link
} from "@mui/material";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Message from "./Message";
import OpenAI from "openai";
import {MessageDto} from "../models/MessageDto";
import SendIcon from "@mui/icons-material/Send";
import AttachFile from "@mui/icons-material/AttachFile";
import ErrorIcon from "@mui/icons-material/Error";
import Loader from "./Loader";
import HeroPanel from "./HeroPanel";
import {useDropzone} from 'react-dropzone';
import "./Chat.css";
import MessagesPanel from "./MessagesPanel";
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import ActionIcon from '@mui/icons-material/Delete';
import MessageComposer from "./MessageComposer";
import MessageErrorPanel from "./MessageErrorPanel";
import accountSelection from "./AccountSelection";
import {io} from 'socket.io-client';
import {Cuenta} from "types/Cuenta";
import {useAppContext} from "hooks/useAppContext";
import {useConversacionContext} from "../hooks/useConversacionContext";
import useMensajes from "../hooks/chat/useMensajes";
import useConversaciones from "../hooks/useConversaciones";
import useSocket from "../hooks/chat/useSocket";
import ChatBar from "./ChatBar";
import MessagesList from "./MessagesList";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Refresh} from "@mui/icons-material";
import moment from "moment-timezone";

type ChatProps = {
    actualizarSidebar: () => void;
    setToolbarIcons?: CallableFunction;
    conversacionId?: string;
};

/**
 * Chat Component
 *
 * Props:
 * - actualizarSidebar: Function - Function to update the sidebar.
 * - setConversationId: Function - Function to set the conversation ID.
 * - nuevaConversacion: boolean - Flag to indicate if it's a new conversation.
 * - setToolbarIcons: Function - Function to set the toolbar icons.
 * - setConversationTitle: Function - Function to set the conversation title.
 *
 * State:
 * - isWaiting: boolean - State to manage the waiting state of the chat.
 * - isMessageWaiting: boolean - State to manage the waiting state of the message.
 * - conversationData: any - State to store the conversation data.
 * - messages: array - Array to store the messages.
 * - groupedMessages: array - Array to store the grouped messages.
 * - ultimoMessageId: string - The ID of the last message.
 * - input: string - The input of the chat.
 * - files: array - Array to store the files.
 * - hayMasPaginas: boolean - State to manage if there are more pages.
 * - cargoLista: boolean - State to manage if the list is loaded.
 * - open: boolean - State to manage the opening and closing of the modal.
 *
 * Methods:
 * - handleClickOpen: Function to handle the opening of the modal.
 * - handleClose: Function to handle the closing of the modal.
 * - eliminarConversacion: Function to delete the conversation.
 * - traerMensajesConversacion: Function to fetch the conversation messages.
 * - createNewMessage: Function to create a new message.
 * - handleSendMessage: Function to handle the sending of the message.
 * - handleKeyPress: Function to handle the pressing of the enter key.
 */
const Chat = (props: ChatProps) => {
    // propiedades
    const {
        actualizarSidebar,
        setToolbarIcons,
        conversacionId
    } = props;


    const REQUERIMIENTO_TOKENS_PARA_SCROLL = 25;
    const llamadasScrollCount = useRef(0);

    const {conversationId} = useParams();
    const location = useLocation();
    const [nuevaConversacion, setNuevaConversacion] = useState(true);
    const queryParams = new URLSearchParams(location.search);
    const r = queryParams.get('r');


    const theme = useTheme();
    const navigate = useNavigate();

    const {
        appEmbebida,
        asistenteSeleccionado,
        conversaciones,
        conversacionSeleccionada,
        setConversacionSeleccionada,
        appBootstrapping,
        errorSistema,
        idConversacion, setIdConversacion,
        setConversaciones,
        addNotification
    } = useAppContext();


    const {
        mensajes, setMensajes,
        mensajesAgrupados, setMensajesAgrupados,
        mensajeEnConstruccion, setMensajeEnConstruccion,
        hayMasPaginas, setHayMasPaginas,
        isMessageWaiting, setIsMessageWaiting,
        ultimoMessageId, setUltimoMessageId,
        nuevoMensajeToolCall,
        nuevoMensajeRunStepError, setNuevoMensajeRunStepError,
        cargoLista, setCargoLista,
        isWaiting, setIsWaiting,
        inputError, setNuevoMensajeInput, nuevoMensajeInput,
        ultimoMensajeInput,
        conversacionCerrada, setConversacionCerrada,
        setFocus, mensajeError, setMensajeError,
        setNuevoMensajeAdjuntos, nuevoMensajeRunId,
        nuevoMensajeToolInfo
    } = useConversacionContext();

    const {cargarMensajes, agruparMensajes, refrescarListaDeMensajes, enviarMensaje, iniciarNuevaConversacion, obtenerMensajesApi} = useMensajes();
    const {actualizarConversaciones, eliminarConversacion} = useConversaciones();

    // mensajes y paginador
    const chatContainerRef = useRef(null);
    const finalMensajeRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { socket, socketConectado, cancelarRun } = useSocket();

    // eliminar conversacion
    const [modalEliminarConversacion, setModalEliminarConversacion] = useState(false);

    const clickBotonEliminar = useCallback(() => {
        setModalEliminarConversacion(true);
    }, [setModalEliminarConversacion]);

    const handleClose = useCallback(() => {
        setModalEliminarConversacion(false);
    }, [setModalEliminarConversacion]);

    const eliminarConversacionActual = useCallback(async () => {
        setModalEliminarConversacion(false);
        if (eliminarConversacion()) {
            actualizarConversaciones();
            navigate("/");
        } else {
            alert("no se puede eliminar la conversacion");
        }

    }, [eliminarConversacion, actualizarConversaciones, navigate]);

    // funciones

    /**
     * Función que envía un mensaje interactuando con el hook useMensajes.
     *
     * @param inputPregunta el mensaje que se desea enviar. si esta vacío se usa nuevoMensajeInput
     * @param agregarMensaje si se debe agregar el mensaje a la lista de mensajes de la conversación
     */
    const handleEnviarMensaje = useCallback(async (inputPregunta: string = null, agregarMensaje: boolean = true) => {
        // manejamos la inicialización de la conversación acá
        setMensajeError(null);
        setIsWaiting(true);
        let creamosNuevaConversacion = false;

        let idConversacion = conversacionSeleccionada?.id;
        if ((nuevaConversacion && !idConversacion)) {
            idConversacion = await iniciarNuevaConversacion();
            if (!idConversacion) {
                // throw new Error("No se pudo iniciar una nueva conversación");
                return;
            }


            let fechaUltimoMensaje = moment().format("YYYY-MM-DD HH:mm:ss");
            setConversacionSeleccionada({
                id: idConversacion,
                titulo: "Nueva conversación",
                es_prueba: false,
                activo: true,
                assistant_id: "",
                fecha_creacion: "",
                fecha_ultimo_mensaje: fechaUltimoMensaje
            });

            setIdConversacion(idConversacion);
            creamosNuevaConversacion = true

        }

        let idMensaje = await enviarMensaje(idConversacion, inputPregunta, agregarMensaje);
        if (idMensaje) {
            if (creamosNuevaConversacion) {
                window.history.pushState({}, '', "/ver-conversacion/" + idConversacion);
            }

            // if (chatContainerRef.current) {
            //     let altura = chatContainerRef.current.scrollHeight;
            //     chatContainerRef.current.style.height = `${chatContainerRef.current.scrollHeight}px`;
            // }

            //console.log("llamo a refrescar ", idConversacion);
            refrescarListaDeMensajes(idConversacion, true, creamosNuevaConversacion);

            // if (chatContainerRef.current) {
            //     chatContainerRef.current.style.height = "";
            // }

            llamadasScrollCount.current = 0;
            setFocus(true);
        }
    }, [conversacionSeleccionada, nuevoMensajeInput, setConversacionSeleccionada, refrescarListaDeMensajes, setMensajeError, setIsWaiting, setFocus]);

    /**
     * Función que frena el mensaje actual
     */
    const handleFrenarMensaje = useCallback(() => {
        if (!isWaiting) {
            return;
        }

        // alert(`frenamos el mensaje/run ${nuevoMensajeRunId} : no implementado`)
        cancelarRun();
    }, [isWaiting, ultimoMensajeInput, nuevoMensajeRunId]);

    const iniciarPregunta = useCallback((pregunta) => {
        handleEnviarMensaje(pregunta);
    }, [handleEnviarMensaje]);

    /**
     * Función que inicia la conversación y envía el mensaje
     * Interactúa con el hook useMensajes para realizar ambas funciones
     * Puede recibir el input de la pregunta y sino la levante del input del MessageComposer
     *
     * @param inputPregunta
     * @param agregarMensaje
     */

    // detect enter key and send message
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            handleEnviarMensaje();
        }
    };

    const obtenerAlturaChat = useMemo(() => {
        if (appEmbebida) {
            return "50vh";
        }

        return !isMobile ? "92vh" : "100vh"; // ? "92vh" : "50vh";
    }, []);

    /**
     * Metodo que se encarga en caso de que exista de enviar el scroll hasta el final de los mensajes existentes.
     * También puede ser llamado durante el proceso de stream pero el funcionamiento puede ser refinado.
     */
    const scrollearHastaAbajo = () => {
        if (!finalMensajeRef.current) {
            return;
        }

        //console.log("scrolleo hasta abajo");
        finalMensajeRef.current.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (appBootstrapping) {
            return;
        }

        const esNuevaConversacion = !conversationId ? true : false;
        setNuevaConversacion(esNuevaConversacion);
        setIdConversacion(conversationId)
        setTimeout(() => {setFocus(true)}, 1000);
        // setTimeout(() => {scrollearHastaAbajo()}, 1500);
        // console.log("cambio id conversacion", conversationId);
    }, [conversationId, appBootstrapping]);

    /**
     * Reset general cada vez que se cambia el conversationId (por parametro)
     */
    useEffect(() => {
        const esNuevaConversacion = !idConversacion;

        // si estoy actualizando el id de una nueva conversacion no hago nada
        if (!esNuevaConversacion && nuevaConversacion) {
            return;
        }

        setMensajes([]);
        llamadasScrollCount.current = 0;
        setMensajesAgrupados([]);
        setMensajeEnConstruccion(null);
        setMensajeError(null);
        setIsWaiting(false);
        setNuevoMensajeRunStepError(false);
        setNuevoMensajeInput("");
        setNuevoMensajeAdjuntos(prevAdjuntos => []);
        setIsMessageWaiting(!esNuevaConversacion);
        setUltimoMessageId("");
        setCargoLista(false);
        setHayMasPaginas(false);

        if (idConversacion) {
            //console.log("cargo mensajes", idConversacion);
            cargarMensajes(idConversacion, null, true);
            setToolbarIcons([
                {icon: <ActionIcon/>, onClick: clickBotonEliminar},
                // Agrega aquí más iconos si los necesitas
            ]);

        } else {
            setConversacionCerrada(false);
        }
    }, [idConversacion]);

    /**
     * Procesa el id y las conversaciones para seleccionar la conversacion
     *
     * @todo: esto no tiene que depender de las conversaciones sino levantarlo del backend
     */
    useEffect(() => {
        // sino es nueva tengo que esperar a que se carguen las conversaciones para seleccionarla
        // @todo: hacer que no dependa del sidebar
        if (!conversaciones || !conversationId || conversaciones.length === 0) {
            return;
        }

        // tengo el id por parametro lo busco y cargo la conversacion
        const conversacion = conversaciones.find((conversacion) => conversacion.id === conversationId);
        if (conversacion) {
            setConversacionSeleccionada(conversacion);
        }
    }, [conversaciones, idConversacion, conversationId]);

    /**
     * Cada vez que cambian los mensajes los vuelvo a agrupar
     */
    useEffect(() => {
        if (mensajes && mensajes.length > 0) {
            agruparMensajes();

            setTimeout(() => {
                scrollearHastaAbajo();
            }, 500);
        }
    }, [mensajes]);

    /**
     * Cada vez que cambian los mensajes agrupados scrollea hasta el final
     */
    // useEffect(() => {
    //     if ((!mensajesAgrupados || mensajesAgrupados.length === 0)) {
    //         return;
    //     }
    //
    //     setTimeout(() => {
    //         scrollearHastaAbajo();
    //     }, 1000);
    //
    // }, [mensajesAgrupados]);

    /**
     * Cada vez que cambia el mensaje en construccion scrollea hasta el final
     */
    useEffect(() => {
        if (!mensajeEnConstruccion) {
            if (llamadasScrollCount.current > 0) {
                setTimeout(() => {
                    // console.log("scrolleo al iniciar");
                    scrollearHastaAbajo();
                }, 500);
            }
            return;
        }

        // console.log("llamadasScrollCount.current", llamadasScrollCount.current);
        if (llamadasScrollCount.current === 0) {
            setTimeout(() => {
                // console.log("scrolleo al iniciar");
                scrollearHastaAbajo();
            }, 1000);
        }


        llamadasScrollCount.current += 1;

        if (llamadasScrollCount.current === REQUERIMIENTO_TOKENS_PARA_SCROLL) {
            setTimeout(() => {
                // console.log("scrolleo cada "+REQUERIMIENTO_TOKENS_PARA_SCROLL+" llamadas");
                scrollearHastaAbajo();
            }, 1000);

            llamadasScrollCount.current = 0; // Reinicia el conteo
        }
    }, [mensajeEnConstruccion]);

    if (isMessageWaiting) {
        return <Loader notFullHeight={appEmbebida}/>;
    }

    return (
        <Container
            id={"chat-container"}
            style={{
                padding: isMobile ? "12px 40px 0px 40px" : "25px 40px 0px 40px",
                boxSizing: "border-box",
                height: obtenerAlturaChat,
                marginTop: isMobile ? 0 : "4vh", //Este margen existe para que no se pegue al borde externo del contenedor, en caso de que hubiera problemas en el diseño con un navbar no contemplado, el valor pasaria a se 10vh
                marginBottom: isMobile ? 0 : '4vh',
                backgroundColor: (mensajes.length == 0 && !isWaiting) ? 'transparent' : theme.palette.backgroundListaMensajes.main,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '10px',
            }}>
            <div ref={chatContainerRef} style={{
                flex: '1 1 auto', // Esto hará que la lista de mensajes ocupe todo el espacio disponible
                //marginBottom: '0.5rem', // Este es el margen que mencionaste
                //marginTop: '75px',
                padding: '0 0 0px',
                msOverflowStyle: "none", /* for Internet Explorer, Edge */
                scrollbarWidth: 'none', /* for Firefox */
                overflowY: 'scroll',
            }}>
                {mensajes && mensajes.length > 0 && <ChatBar></ChatBar>}
                <Dialog
                    open={modalEliminarConversacion}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        {"Eliminar conversación"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Estás seguro de que quieres eliminar esta conversación?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={eliminarConversacionActual} color="primary" autoFocus>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>

                {
                    mensajesAgrupados.length < 20 ? null : hayMasPaginas && (
                        <div style={{margin: "20px auto", width: "auto", textAlign: "center"}}>
                            <Button variant={"outlined"} color={"success"} onClick={() => {
                                cargarMensajes(ultimoMessageId);
                            }}>
                                Cargar mensajes anteriores ...
                            </Button>
                        </div>
                    )
                }

                {mensajesAgrupados.length > 0 || isWaiting ? (
                    <MessagesList
                        conversationId={conversationId}
                        asistenteSeleccionado={asistenteSeleccionado}
                        gruposDeMensajes={mensajesAgrupados}
                        isWaiting={isWaiting}
                    />
                ) : (
                    <HeroPanel
                        assistant={asistenteSeleccionado}
                        iniciarPregunta={iniciarPregunta}
                    />
                )}


                {mensajeError && (
                    <MessageErrorPanel
                        mostrarReload={true}
                        mensajeError={mensajeError}
                        callbackReload={() => {
                        handleEnviarMensaje(ultimoMensajeInput, false);
                        }}
                    />
                )}

                {/*MARGEN*/}
                <Box ref={finalMensajeRef} sx={{
                    mt: 1,
                }}>&nbsp;</Box>

                {conversacionSeleccionada && conversacionCerrada && (
                    <Box width={"100%"} style={{"paddingBottom": "50px"}} textAlign={"center"}>
                        <Typography variant={isMobile ? "h5" : "h2"}>La conversación ha finalizado</Typography>
                        <Typography>
                            Por favor, inicia una nueva para que podamos seguir brindándote asistencia.
                        </Typography>
                        <Button onClick={() => {
                            navigate("/nueva-conversacion")
                        }}>Nueva conversación +</Button>
                    </Box>
                )}
            </div>

            {!conversacionCerrada && (
                <div style={{
                    flexShrink: 0, // Esto evitará que el panel de composición se encoja
                    position: 'sticky',
                    bottom: 0,
                }}>
                    <MessageComposer
                        handleSendMessage={handleEnviarMensaje}
                        handleStopRun={handleFrenarMensaje}
                        isWaiting={isWaiting}
                        isEmbed={appEmbebida}
                        setIsWaiting={setIsWaiting}
                        assistant={asistenteSeleccionado}
                        inputError={inputError}
                        nuevaConversacion={nuevaConversacion}
                    />
                </div>
            )}
        </Container>
    );
};

export default Chat;
