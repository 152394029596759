class ApiError extends Error {
    statusCode: number;
    payload: any;

    constructor(message: string, statusCode: number, payload: any = null) {
        super(message); // Llama al constructor de la clase Error
        this.statusCode = statusCode; // Inicializa la propiedad statusCode
        this.name = "ApiError"; // Personaliza el nombre del error
        this.payload = payload;
    }
}

export default ApiError;
