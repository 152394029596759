import React, { createContext, useState, ReactNode, useCallback } from 'react';
import {Cuenta} from "types/Cuenta";
import {Asistente} from "../types/Asistente";
import {Conversacion} from "../types/Conversacion";
import {Usuario} from "../types/Usuario";

interface AppContextType {
    appBootstrapping: boolean;
    setAppBootstrapping: (appBootstrapping: boolean) => void;
    accountConfig: any;
    setAccountConfig: (accountConfig: any) => void;
    errorSistema: string;
    setErrorSistema: (errorSistema: string) => void;
    appEmbebida: boolean;
    setAppEmbebida: (appEmbebida: boolean) => void;
    apiAccesible: boolean;
    setApiAccesible: (apiAccesible: boolean) => void;
    cuentas: Cuenta[];
    setCuentas: (cuentas: Cuenta[]) => void;
    cuentaSeleccionada: Cuenta; 
    setCuentaSeleccionada: (cuenta: any) => void; 
    asistenteSeleccionado: Asistente; 
    setAsistenteSeleccionado: (asistente: Asistente) => void; 
    conversaciones: any[]; 
    setConversaciones: (conversaciones: Conversacion[]) => void; 
    conversacionSeleccionada: any; 
    setConversacionSeleccionada: (conversacion: any) => void; 
    timezone: string;
    setTimezone: (timezone: string) => void;
    idConversacion: string;
    setIdConversacion: (idConversacion: string) => void;
    debug: boolean;
    setDebug: (debug: boolean) => void;
    debugInfo: any;
    setDebugInfo: (debugInfo: any) => void;
    mostrarSidebar: boolean;
    setMostrarSidebar: (mostrarSidebar: boolean) => void;
    notifications: Notification[];
    addNotification: (message: string, severity: 'success' | 'info' | 'warning' | 'error') => void;
    removeNotification: (id: number) => void;
}

interface Notification {
    id: number;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
}


export const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}

/**
 * Proveedor de contexto de la aplicación
 *
 * @param children
 * @constructor
 */
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {

    // gestiona el loading de la app
    const [appBootstrapping, setAppBootstrapping] = useState<boolean>(true);
    const [debug, setDebug] = useState<boolean>(true);
    const [errorSistema, setErrorSistema] = useState<string|null>(null);
    const [idConversacion, setIdConversacion] = useState(null);
    const [debugInfo, setDebugInfo] = useState<any>({"app": {}, "cuenta": {}, "conversacion": {}, "usuario": {}});

    // tipo de app
    const [appEmbebida, setAppEmbebida] = useState<boolean>(false);
    const [timezone, setTimezone] = useState<string>("America/Argentina/Buenos_Aires");

    // variable que gestiona si la api esta accesible o no
    const [apiAccesible, setApiAccesible] = useState<boolean>(false);

    const [accountConfig, setAccountConfig] = useState<any>(null)

    // gestion de las cuentas
    const [cuentas, setCuentas] = useState<Cuenta[]>([]);
    const [cuentaSeleccionada, setCuentaSeleccionada] = useState<Cuenta>();
    const [asistenteSeleccionado, setAsistenteSeleccionado] = useState<Asistente>();

    // conversaciones
    const [conversaciones, setConversaciones] = useState<Conversacion[]|null>(null);
    const [conversacionSeleccionada, setConversacionSeleccionada] = useState<Conversacion>();

    const [mostrarSidebar, setMostrarSidebar] = useState<boolean>(true);

    const [notifications, setNotifications] = useState<Notification[]>([]);

    const addNotification = useCallback((message: string, severity: 'success' | 'info' | 'warning' | 'error') => {
        const id = Date.now();
        setNotifications(prev => [...prev, { id, message, severity }]);
    }, [setNotifications]);

    const removeNotification = useCallback((id: number) => {
        setNotifications(prev => prev.filter(notification => notification.id !== id));
    }, [setNotifications]);

    const value = {
        appBootstrapping, setAppBootstrapping,
        appEmbebida, setAppEmbebida,
        apiAccesible, setApiAccesible,
        cuentas, setCuentas,
        cuentaSeleccionada, setCuentaSeleccionada,
        conversaciones, setConversaciones,
        conversacionSeleccionada, setConversacionSeleccionada,
        asistenteSeleccionado, setAsistenteSeleccionado,
        errorSistema, setErrorSistema,
        timezone, setTimezone,
        idConversacion, setIdConversacion,
        accountConfig, setAccountConfig,
        debug, setDebug,
        mostrarSidebar, setMostrarSidebar,
        debugInfo, setDebugInfo,
        notifications, addNotification, removeNotification
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};