import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const fingerprintService = {
    async generarFingerprint(): Promise<string> {
        try {
            const fpPromise = FingerprintJS.load();
            const fp = await fpPromise;
            const result = await fp.get();
            return result.visitorId;
        } catch (error) {
            console.error("Error al generar fingerprint:", error);
            throw error;
        }
    },

    // Aquí puedes añadir más métodos relacionados con el fingerprint si es necesario
};