import React, {useCallback, useEffect, useRef, useState} from "react";
import useConversaciones from "../../hooks/useConversaciones";
import {useTheme} from "@mui/material/styles";
import {Box, Fade, IconButton, ListItemButton, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { CircularProgress } from "@mui/material";
import {useAppContext} from "hooks/useAppContext";

const SidebarListItem = ({
                                 conversation,
                                 setConversacionSeleccionada,
                                 conversacionSeleccionada,
                                 navigate,
                                 posicion
                             }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState<string>(conversation.titulo);
    const [isUpdating, setIsUpdating] = useState(false);
    const hoverTimerRef = useRef(null);
    const {accountConfig, addNotification} = useAppContext();

    const {
        eliminarConversacion,
        eliminarConversacionLocal,
        actualizarConversaciones,
        actualizarTituloConversacion
    } = useConversaciones();
    const inputRef = useRef(null); // Agrega esta línea
    const theme = useTheme();


    const handleTitleChange = (e) => {
        setEditedTitle(e.target.value);
    }

    const handleTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            //console.log("titulo", editedTitle);
            handleConfirmEdit(e)
        }
    }

    const eliminarConversacionMenu = useCallback(async () => {
        if (!conversation) {
            return;
        }

        let conversacion = conversation;
        if (!conversacion) {
            return;
        }

        console.log("eliminando conversacion", conversacion);

        if (eliminarConversacion(conversacion.id)) {
            eliminarConversacionLocal(conversacion.id);
            addNotification('Conversación eliminada con éxito', 'success');

            // si es justo la que estamos eliminando, navegamos a home
            if (conversacion.id == conversacionSeleccionada?.id) {
                navigate("/");
            }
        } else {
            alert("no se puede eliminar la conversacion");
        }
    }, [conversation, conversacionSeleccionada]);

    const handleEditClick = (e) => {
        e.stopPropagation();
        setIsEditing(true);
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 250); // Agrega esta línea
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
        hoverTimerRef.current = setTimeout(() => {
            setShowButtons(true);
        }, 200);
    };

    const handleConfirmEdit = (e) => {
        e.stopPropagation();
        setIsUpdating(true);
        actualizarTituloConversacion(conversation.id, editedTitle);
        addNotification("Se actualizó el título correctamente.", "success");
        setIsUpdating(false);
        setIsEditing(false);
        setIsHovered(false);
        setShowButtons(false);
    };

    const handleBlur = () => {
        setIsEditing(false);
        setIsHovered(false);
        setShowButtons(false);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setShowButtons(false);
        if (hoverTimerRef.current) {
            clearTimeout(hoverTimerRef.current);
        }
    };

    const formatearTitulo = useCallback((titulo: string, maxLength: number = 24) => {
        if (!titulo) return "Sin título";

        if (accountConfig && accountConfig.sidebar && accountConfig.sidebar.maxCaracteresTitulo) {
            maxLength = accountConfig.sidebar.maxCaracteresTitulo;
        }


        if (titulo.length <= maxLength) return titulo;
        return `${titulo.substring(0, maxLength)}...`;
    }, []);

    const selected = conversacionSeleccionada ? conversation.id === conversacionSeleccionada.id : false;

    useEffect(() => {
        return () => {
            if (hoverTimerRef.current) {
                clearTimeout(hoverTimerRef.current);
            }
        };
    }, []);

    return (
        <ListItemButton
            key={conversation.id}
            selected={selected}
            onClick={!isEditing ? () => {
                setConversacionSeleccionada(conversation);
                navigate("/ver-conversacion/" + conversation.id);
                document.getElementById('menuButton')?.click();
            } : () => {
            }}

            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                '&.Mui-active': {
                    backgroundColor: "inherit",
                },
                '&.Mui-focusVisible': {
                    backgroundColor: 'transparent',
                },
            }}
        >

            {isEditing ? (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <input
                        value={editedTitle}
                        onChange={handleTitleChange}
                        onKeyDown={handleTitleKeyDown}
                        ref={inputRef}
                        onBlur={handleBlur}
                        style={{
                            padding: '5px',
                            fontSize: '0.9rem',
                            fontFamily: 'Work Sans',
                            flexGrow: 1,
                        }}
                        disabled={isUpdating}
                    />
                    {isUpdating ? (
                        <CircularProgress size={24} sx={{ ml: 1 }} />
                    ) : (
                        <IconButton
                            edge="end"
                            size="small"
                            aria-label="confirm"
                            onClick={handleConfirmEdit}
                            sx={{ ml: 1 }}
                        >
                            <CheckIcon />
                        </IconButton>
                    )}
                </Box>
            ) : (
                <Typography title={conversation.titulo} style={{margin: "5px 0", padding: "0"}}>
                    {formatearTitulo(conversation.titulo, 32)}
                </Typography>
            )}
            {isHovered && !isEditing && (
                <Fade in={showButtons} timeout={300}>
                    <Box sx={{
                        position: 'absolute',
                        right: 0,
                        marginRight: "15px",
                        backgroundColor: selected ? theme.palette.primary.main : "inherit",
                        color: selected ? "white" : "inherit"
                    }}>
                        <IconButton
                            edge="end"
                            size={"medium"}
                            aria-label="edit"
                            sx={{
                                fontSize: 24,
                                color: selected ? theme.palette.primary.contrastText : 'inherit',
                                '&:hover': {
                                    backgroundColor: 'auto',
                                },
                            }}
                            onClick={handleEditClick}
                        >
                            <EditIcon/>
                        </IconButton>
                        <IconButton
                            edge="end"
                            size={"medium"}
                            aria-label="delete"
                            sx={{
                                fontSize: 24,
                                color: selected ? theme.palette.primary.contrastText : 'inherit',
                                '&:hover': {
                                    backgroundColor: 'auto',
                                },
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (window.confirm("Está seguro?")) {
                                    eliminarConversacionMenu();
                                }
                                return false;
                            }}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                </Fade>
            )}
        </ListItemButton>
    );
};

export default SidebarListItem;