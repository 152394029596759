import {useCallback, useEffect, useRef} from 'react';
import {useAppContext} from 'hooks/useAppContext';
import {useLocation} from "react-router-dom";
//import fetchAPI from "../api";
import useFetchAPI from "hooks/useFetchApi";
import {Cuenta} from "../types/Cuenta";
import {Usuario} from "../types/Usuario";
import {useAuthContext} from "../contexts/AuthContext";
import {authService} from "../services/authService";
import {fingerprintService} from "../services/fingerprintService";

/**
 * Hook para inicializar/bootstrapear la aplicación
 * Sus responsabilidades en orden son:
 *  - Chequear si hay un token de sistema en localStorage
 *  - Sino hay chequear si hay un token de usuario en la URL y autenticarlo
 *      - Si no hay token de usuario, mostrar un error
 *      - Validar el token de usuario contra la api de autenticacion
 *      - Guardar el token de usuario en localStorage si es valido sino mostrar erro
 *  - Si hay validar el token de sistema contra la api de autenticacion
 *      - Si el token no es válido mostrar error
 *  - Cargar los datos del usuario
 *  - Cargar las cuentas del usuario
 *  - Seleccionar una cuenta por defecto si hay una sola
 *  - Seleccionar una cuenta si hay una en la URL
 *  - Exponer funciones para levantar las cuentas y seleccionar una cuenta
 *
 * @returns {Object} - Objeto con las funciones de levantarCuentas y seleccionarCuenta
 */
const useInicializadorApp = () => {
    const location = useLocation();

    const { setTokenUsuario, tokenSistema, setTokenSistema, tokenSistemaValido, setTokenSistemaValido, usuario, setUsuario } = useAuthContext();
    const inicializacionEnCurso = useRef(false);

    const {
        setAppBootstrapping,
        setApiAccesible,
        errorSistema, setErrorSistema,
        setAppEmbebida,
        setAsistenteSeleccionado,
        setCuentas, cuentas,
        setCuentaSeleccionada,
        apiAccesible,
        debug, setDebug,
        debugInfo, setDebugInfo,
        timezone, setTimezone,
        accountConfig, setAccountConfig,
        mostrarSidebar, setMostrarSidebar
    } = useAppContext();

    const fetchAPI = useFetchAPI();

    const extraemosTokensUsuario = (): string => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        return token;
    };

    const extraerTokenUsuario = useCallback((): string | null => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('token');
    }, [location.search]);

    const inicializarApp = useCallback(async () => {
        if (inicializacionEnCurso.current) return;
        inicializacionEnCurso.current = true;

        try {
            setAppBootstrapping(true);

            // 1. Levantar cuentas
            let cuentas = await levantarCuentas();

            // 2. Seleccionar cuenta (si es posible)
            const cuentaSeleccionada = await seleccionarCuentaPorDefecto(cuentas);

            if (cuentaSeleccionada) {
                // 3. Configurar la aplicación basada en la cuenta
                await configurarApp(cuentaSeleccionada);
                // console.log("Cuenta seleccionada:", cuentaSeleccionada);

                // 4. Autenticar usuario
                await autenticarUsuario(cuentaSeleccionada);
            }

        } catch (error) {
            setErrorSistema(error.message || "Error durante la inicialización");
        } finally {
            setAppBootstrapping(false);
            inicializacionEnCurso.current = false;
        }
    }, []);

    const seleccionarCuentaPorDefecto = async (cuentas: Cuenta[]) => {
        let cuentaSeleccionada = null;
        // console.log("cuentas", cuentas);

        const cuentaDeUrl = extraerCuentaDeUrl();
        if (cuentaDeUrl) {
            cuentaSeleccionada = cuentas.find(cuenta => cuenta.nombre.toLowerCase() === cuentaDeUrl.toLowerCase());
        }

        if (!cuentaSeleccionada && cuentas.length === 1) {
            cuentaSeleccionada = cuentas[0];
        }

        if (cuentaSeleccionada) {
            // console.log("seleccionando cuenta", cuentaSeleccionada);
            await seleccionarCuenta(cuentaSeleccionada);
        }

        return cuentaSeleccionada;
    };

    /**
     * Configura la aplicación y carga el modulo de customización de javascript.
     * @param cuenta
     */
    const configurarApp = async (cuenta: Cuenta) => {
        // setAsistenteSeleccionado(cuenta.assistant);
        if (cuenta.assistant.js_module) {
            const js_module = cuenta.assistant.js_module.replace(/\./g, '/');
            try {
                const module = await import(`../customizations/${js_module}/config.tsx`);
                setAccountConfig(module.default);
            } catch (e) {
                setAccountConfig(null);
            }
        }
    };

    const autenticarUsuario = async (cuenta: Cuenta) => {
        let tokenSistema = authService.obtenerToken();
        let tokenUsuario = null;
        const tipoLogin = cuenta.tipo_autenticacion || "token_exchange";

        if (tipoLogin === "token_exchange") {
            tokenUsuario = extraerTokenUsuario();
        } else if (tipoLogin === "anonymous") {
            tokenUsuario = await fingerprintService.generarFingerprint();
            // console.log("token generado por fingerprint", tokenUsuario);
            let ultimoTokenUsado = authService.obtenerUltimoTokenUsado();
            if (ultimoTokenUsado && ultimoTokenUsado === tokenUsuario) {
                // si el fingerprint es igual al ultimo token usado, lo ponemos en cero
                tokenUsuario = null;
            }
        } else if (tipoLogin === "login") {
            // setErrorSistema("No se puede iniciar sesión desde la aplicación");
            if (!tokenSistema) {
                // Si no hay token de sistema, no podemos autenticar y tiene que capturarlo el login
               return;
            }
        } else {
            throw new Error("Tipo de autenticación no reconocido");
        }

        try {
            if (tokenUsuario) {
                // Si hay un token en la URL, úsalo para obtener un nuevo token del sistema
                tokenSistema = await authService.intercambiarToken(tokenUsuario, true);
            } else if (!tokenSistema) {
                // Si no hay token en la URL ni en localStorage, no podemos autenticar
                // console.log("No se encontró token de autenticación");
                setErrorSistema("No se encontró token de autenticación");
                return;
            }

            // Si llegamos aquí, tenemos un token de sistema (ya sea nuevo o existente)
            setTokenSistema(tokenSistema);

            // Intentamos obtener la información del usuario
            const { usuario, timezone } = await authService.obtenerInfoUsuario();
            if (usuario) {
                // console.log("Usuario:", usuario);
                setUsuario(usuario);
                setTimezone(timezone);
                setTokenSistemaValido(true);
                // console.log("Usuario autenticado:", usuario);
            } else {
                throw new Error("No se pudo obtener la información del usuario");
            }
        } catch (error) {
            console.error("Error durante la autenticación:", error);
            setErrorSistema("Error de autenticación: " + error.message);
            // Aquí podrías manejar el error, por ejemplo, redirigiendo a una página de login
        }
    };

    const chequeamosAppEmbebida = () => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('embed') !== null) {
            setAppEmbebida(true);
        }
    };

    const extraerCuentaDeUrl = () => {
        // Obtén la ruta actual
        let _cuentaSeleccionada = null;
        const path = location.pathname;

        // Divide la ruta en segmentos
        const segments = path.split('/');

        // Verifica si el segundo segmento existe
        if (segments[1]) {
            return segments[1];
        }

        return null;
    }

    const levantarCuentas = async () => {
        let url = "/accounts";
        if (debug) {
            url = `/accounts?debug=1`;
        }

        const response = await fetchAPI(url, 'GET', null, false);
        if (!response || !response.accounts || response.accounts.length === 0) {
            throw new Error("No hay cuentas disponibles");
        }
        setCuentas(response.accounts);
        // console.log("debug", debug);
        // console.log("debugInfo", response.debug);
        if (debug) {
            setDebugInfo((debugInfo) => { return {...debugInfo, "app": response.debug}});
        }

        return response.accounts;
    };

    // Definir levantarCuentas fuera de useEffect
    const obtenerDatosDeUsuario = async (): Promise<Usuario | null> => {
        try {
            let url = '/auth/obtener-info-usuario';
            if (debug) {
                url += '?debug=1';
            }

            const response = await fetchAPI(url, 'GET');
            if (!response) {
                return null;
            }

            let usuario = response["usuario"];
            let timezone = response["timezone"];
            setTimezone(timezone);

            if (debug) {
                setDebugInfo((debugInfo) => { return {...debugInfo, "usuario": response['debug']}});
            }

            return usuario;

        } catch (error: any) {
            setErrorSistema(error.message);
        }

    }

    const seleccionarCuenta = async (cuenta: Cuenta) => {
        // console.log("por bardear");
        const response = await fetchAPI(`/accounts/select/${cuenta.id}`, 'GET', null, false);
        if (!response) {
            setApiAccesible(false);
            return;
        }

        let data = response;
        if (data["status"] == "ok") {
            setCuentaSeleccionada(cuenta);
            setAsistenteSeleccionado(cuenta.assistant);
            if (cuenta.maximo_conversaciones && cuenta.maximo_conversaciones === 1) {
                setMostrarSidebar(false);
            }
        } else {
            alert("Error al seleccionar la cuenta");
            setApiAccesible(false);
        }

        if (cuenta.assistant.js_module) {
            // console.log("asistente con js module", assistant.js_module);
            let js_module = cuenta.assistant.js_module
            // replace dots with slashes
            js_module = js_module.replace(/\./g, '/');

            import("../customizations/"+js_module+"/config.tsx").then((module) => {
                let config = module.default;
                // console.log("cargo la configuracion");
                setAccountConfig(config);
            }).catch((e) => {
                setAccountConfig(null);
            });
        }
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            inicializarApp();
        }, 300); // Pequeño debounce de 100ms


        return () => clearTimeout(debounceTimer);
    }, [inicializarApp]);

    useEffect(() => {
        if (!usuario) {
            return;
        }

        chequeamosAppEmbebida();
    }, [usuario]);


    // Exponer levantarCuentas para uso externo
    return {levantarCuentas, seleccionarCuenta};
};

export default useInicializadorApp;
