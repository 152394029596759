import { useState, useEffect, useCallback, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppContext } from "hooks/useAppContext";

const useSidebarLayout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { accountConfig } = useAppContext();

    const [scrollHeight, setScrollHeight] = useState('100%');
    const [scrollableHeight, setScrollableHeight] = useState('100%');
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const scrollRef = useRef(null);

    const calculateScrollableHeight = useCallback(() => {
        if (headerRef.current && footerRef.current) {
            const headerHeight = headerRef.current.offsetHeight;
            const footerHeight = footerRef.current.offsetHeight;
            const totalHeight = window.innerHeight;
            const newScrollableHeight = totalHeight - headerHeight - footerHeight;
            setScrollableHeight(`${Math.max(newScrollableHeight, 100)}px`);
        }
    }, []);

    useEffect(() => {
        calculateScrollableHeight();
        window.addEventListener('resize', calculateScrollableHeight);
        return () => window.removeEventListener('resize', calculateScrollableHeight);
    }, [calculateScrollableHeight]);

    const scrollToBottom = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, []);

    const obtenerVariante = useCallback(() => {
        if (isMobile) {
            return "temporary";
        } else {
            return accountConfig && accountConfig.sidebar ? accountConfig.sidebar.variant : "permanent";
        }
    }, [accountConfig, isMobile]);

    const obtenerAnchor = useCallback(() => {
        return accountConfig && accountConfig.sidebar ? accountConfig.sidebar.anchor : "right";
    }, [accountConfig]);

    return {
        isMobile,
        scrollHeight,
        scrollableHeight,
        headerRef,
        footerRef,
        scrollRef,
        calculateScrollableHeight,
        scrollToBottom,
        obtenerVariante,
        obtenerAnchor,
    };
};

export default useSidebarLayout;