import {Box, List, Typography} from "@mui/material";
import SidebarStickyHeader from "./SidebarStickyHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import SidebarListItem from "./SidebarListItem";
import React, {useCallback, useEffect, useRef} from "react";
import {useInView} from 'react-intersection-observer';

type SidebarConversationsListProps = {
    scrollRef: any;
    scrollHeight: any;
    currentGroup: any;
    conversaciones: any;
    fetchMoreData: any;
    hasMore: any;
    conversacionSeleccionada: any;
    setConversacionSeleccionada: any;
    contarConversacionesAgrupadas: any;
    conversacionesAgrupadas: any;
    setCurrentGroup: (group: any) => void;
    currentGroupRef: any;
    isLoading: any;
    theme: any;
    navigate: any;
    obtenerGrupoPorEtiqueta: any;
    orderedGroups: any;
    scrollearAlPrincipio: boolean;
    setScrollearAlPrincipio: any;
}

const GroupContainer = ({group, children, onVisibilityChange}) => {
    const {ref, inView} = useInView({
        threshold: 0,
        onChange: (inView) => onVisibilityChange(group, inView),
    });

    return (
        <div ref={ref} id={`group-${group.id}`} data-group-id={group.id}>
            {children}
        </div>
    );
};


const SidebarConversationsList = (props: SidebarConversationsListProps) => {
    const {
        scrollRef,
        scrollHeight,
        currentGroup,
        conversaciones,
        fetchMoreData,
        hasMore,
        conversacionSeleccionada,
        setConversacionSeleccionada,
        contarConversacionesAgrupadas,
        conversacionesAgrupadas,
        isLoading,
        theme,
        navigate,
        setCurrentGroup,
        currentGroupRef,
        obtenerGrupoPorEtiqueta,
        orderedGroups,
        scrollearAlPrincipio,
        setScrollearAlPrincipio
    } = props;

    const scrollToTop = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0, {behavior: 'smooth'});
        }
    }, []);

    const handleGroupVisibilityChange = useCallback((group, isVisible) => {
        // la logica tiene que ser


        const getIndexOrderedGroup = (group) => {
            const index = orderedGroups.findIndex(g => g.id === group.id);
            return index;
        }

        const indexOrderedGroup = getIndexOrderedGroup(group);
        // console.log("cambio grupo", group, isVisible, indexOrderedGroup);

        // @todo: esto si levanta el sidebar de una sin estar oculto puede ser para bardo
        if (!currentGroup && isVisible) {
            // console.log("seteo primer grupo");
            setCurrentGroup(group);
            currentGroupRef.current = group;
            return;
        }

        const indexCurrentGroup = getIndexOrderedGroup(currentGroup);
        // console.log("index current group", indexCurrentGroup);
        // se esta ocultando y es el que esta activo paso al siguiente
        if (!isVisible && indexOrderedGroup <= indexCurrentGroup) {
            let nextGroup = obtenerSiguienteGrupoConConversaciones(indexOrderedGroup, conversacionesAgrupadas, orderedGroups);
            if (nextGroup) {
                // console.log("seteo siguiente grupo", nextGroup);
                setCurrentGroup(nextGroup);
                currentGroupRef.current = nextGroup;
            } else {
                // console.log("no hay siguiente grupo");
            }

            return;
        } else if (isVisible && indexOrderedGroup < indexCurrentGroup) {
            let previousGroup = orderedGroups[indexOrderedGroup];
            // console.log("seteo anterior grupo", previousGroup);
            setCurrentGroup(previousGroup);
            currentGroupRef.current = previousGroup;
            return;
        }

        // este handler se ejecuta cada vez que uno de los inview cambia.
        // lo que puede pasar es que el inview avise que se oculta o que se muestra.
        // tenemos que definir la logica para que esto funcione bien
        // la logica tiene que ser que si un grupo aparece y es visible pero el current group es anterior en orden
        // y todavia no desaparecio entonces no hay que cambiarlo
        // esto implica que cuando un grupo desaparece y el siguiente grupo ya está visible entoncces hay que cambiarlo
        // si un grupo aparece, pero el que esta visible es posterior al actual tambien hay que cambiarlo


        // if (isVisible && currentGroup?.id !== group.id) {
        //     setCurrentGroup(group);
        //     currentGroupRef.current = group;
        // }
    }, [currentGroup, conversacionesAgrupadas, setCurrentGroup]);

    const obtenerSiguienteGrupoConConversaciones = (indexOrderedGroup, conversacionesAgrupadas, orderedGroups) => {
        let nextGroupId = indexOrderedGroup + 1;
        let nextGroup = orderedGroups[nextGroupId];

        // console.log("index current group", indexOrderedGroup);
        // console.log(conversacionesAgrupadas, orderedGroups);


        // tenemos que buscar el siguiente que tenga conversaciones
        for (let i = indexOrderedGroup + 1; i < orderedGroups.length; i++) {
            // console.log("buscando siguiente", i);
            const group = orderedGroups[i];
            // console.log("group", group);
            if (conversacionesAgrupadas[group.label] && conversacionesAgrupadas[group.label].length > 0) {
                // console.log("encontro");
                return group;
            } else {
                // console.log("no hay conversaciones");
            }
        }

        return null;
    }


    const obtenerPrevioGrupoConConversaciones = (indexOrderedGroup, conversacionesAgrupadas, orderedGroups) => {

        // console.log("index current group", indexOrderedGroup);
        // console.log(conversacionesAgrupadas, orderedGroups);


        // tenemos que buscar el siguiente que tenga conversaciones
        for (let i = indexOrderedGroup - 1; i >= 0; i--) {
            // console.log("buscando anterior", i);
            const group = orderedGroups[i];
            // console.log("group", group);
            if (conversacionesAgrupadas[group.label] && conversacionesAgrupadas[group.label].length > 0) {
                // console.log("encontro");
                return group;
            } else {
                // console.log("no hay conversaciones");
            }
        }

        return null;
    }

    const esPrimerGrupo = useCallback((group) => {
        if (!orderedGroups) {
            return true;
        }

        let idx = orderedGroups.findIndex(g => g.id === group.id);
        let primerIdxConMensajes = null;
        for (let i = 0; i < orderedGroups.length; i++) {
            if (conversacionesAgrupadas[orderedGroups[i].label] && conversacionesAgrupadas[orderedGroups[i].label].length > 0) {
                primerIdxConMensajes = i;
                break;
            }
        }

        return primerIdxConMensajes === idx;

    }, [orderedGroups, conversacionesAgrupadas]);

    useEffect(() => {
        if (scrollearAlPrincipio) {
            scrollToTop();
            setScrollearAlPrincipio(false);
        }
    }, [scrollearAlPrincipio, setScrollearAlPrincipio]);

    return (
        <Box
            id="scrollableDiv"
            ref={scrollRef}
            style={{
                height: scrollHeight,
                overflow: 'auto',
                flex: 1,
                position: 'relative', // Añade esta línea
            }}
            className="custom-scrollbar"
        >
            {currentGroup && <SidebarStickyHeader group={currentGroup} isTitle={true}/>}
            <InfiniteScroll
                dataLength={conversaciones ? conversaciones.length : 0}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                    <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="center"
                        py={2}
                        px={4}
                    >
                        <CircularProgress
                            size={24}
                            thickness={4}
                            sx={{
                                color: theme.palette.primary.main,
                            }}
                        />
                    </Box>
                }
                scrollableTarget="scrollableDiv"
                // endMessage={
                //     <Typography variant={"body2"} style={{textAlign: 'center', marginTop: "25px"}}>
                //         <b>No hay más conversaciones.</b>
                //     </Typography>
                // }
                scrollThreshold="400px"
                style={{paddingBottom: '20px'}}
            >
                {contarConversacionesAgrupadas() ? Object.keys(conversacionesAgrupadas).map((group, index) => {
                    if (conversacionesAgrupadas[group].length > 0) {
                        const groupObj = obtenerGrupoPorEtiqueta(group);
                        return (
                            <GroupContainer
                                key={groupObj.id}
                                group={groupObj}
                                onVisibilityChange={handleGroupVisibilityChange}
                            >

                                <SidebarStickyHeader group={groupObj} isVisible={!esPrimerGrupo(groupObj)}/>

                                <List style={{marginLeft: '1em', marginRight: '1em', paddingTop: 0}}>
                                    {conversacionesAgrupadas[group].map((conversation, index_thread) => {
                                        return (

                                            <SidebarListItem key={`${groupObj.id}_${conversation.id}`}
                                                             conversacionSeleccionada={conversacionSeleccionada}
                                                             navigate={navigate}
                                                             setConversacionSeleccionada={setConversacionSeleccionada}
                                                             conversation={conversation}
                                                             posicion={index}
                                            />
                                        );
                                    })}
                                </List>
                            </GroupContainer>
                        );
                    }
                }) : (
                    <div>
                        {!isLoading && (
                            <Typography style={{
                                textAlign: "left",
                                marginTop: "1em",
                                marginLeft: '1em',
                                marginRight: '1em'
                            }}>
                                No tenés conversaciones
                            </Typography>
                        )}
                    </div>
                )}
            </InfiniteScroll>
        </Box>
    )
}

export default SidebarConversationsList;