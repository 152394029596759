import React, { createContext, useState, ReactNode, useContext } from 'react';
import { Usuario } from '../types/Usuario';
import { authService } from '../services/authService';

interface AuthContextType {
    tokenUsuario: string;
    setTokenUsuario: (token: string) => void;
    tokenSistema: string;
    setTokenSistema: (token: string) => void;
    tokenSistemaValido: boolean;
    setTokenSistemaValido: (isValid: boolean) => void;
    usuario: Usuario | null;
    setUsuario: (usuario: Usuario | null) => void;
    iniciarSesion: (token: string) => Promise<void>;
    cerrarSesion: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [tokenUsuario, setTokenUsuario] = useState<string>('');
    const [tokenSistema, setTokenSistema] = useState<string>('');
    const [tokenSistemaValido, setTokenSistemaValido] = useState<boolean>(false);
    const [usuario, setUsuario] = useState<Usuario | null>(null);


    const iniciarSesion = async (token: string) => {
        // Implementar la lógica de inicio de sesión aquí
        // Esta función debería hacer lo siguiente:
        // 1. Autenticar el token de usuario
        // 2. Obtener el token del sistema
        // 3. Establecer el token del sistema y el usuario en el estado
        // 4. Marcar el token del sistema como válido
    };

    const cerrarSesion = () => {
        setTokenUsuario('');
        setTokenSistema('');
        setTokenSistemaValido(false);
        setUsuario(null);
        authService.eliminarToken();
    };

    const value = {
        tokenUsuario,
        setTokenUsuario,
        tokenSistema,
        setTokenSistema,
        tokenSistemaValido,
        setTokenSistemaValido,
        usuario,
        setUsuario,
        iniciarSesion,
        cerrarSesion,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};