// src/App.tsx
import React, { useMemo, useState } from 'react';
import {
    Box,
    Alert,
    ThemeProvider,
    createTheme
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useAppContext } from "hooks/useAppContext";
import { Cuenta } from "./types/Cuenta";
import useInicializadorApp from "./hooks/useInicializadorApp";
import useConversaciones from "./hooks/useConversaciones";
import "./App.css";
import deepmerge from 'lodash/merge';
import baseTheme from "./theme";
import { useAuthContext } from "./contexts/AuthContext";
import LoginPanel from './components/auth/LoginPanel';
import LogoutRoute from "./components/auth/LogoutRoute";
import Notification from "./components/Notification";
import Loader from "./components/Loader";
import Sidebar from "./components/layout/Sidebar";
import Home from "./components/Home";
import Chat from './components/Chat';
import DebugBar from "./components/DebugBar";

/**
 * Componente para rutas protegidas
 * @param {Object} props - Propiedades del componente
 * @param {React.ReactNode} props.children - Componentes hijos
 */
const RutaProtegida = ({children}) => {
    const {usuario, tokenSistemaValido} = useAuthContext();
    const {cuentaSeleccionada, appBootstrapping} = useAppContext();

    if (appBootstrapping) {
        return;
    }

    if ((!usuario || !tokenSistemaValido) && cuentaSeleccionada && cuentaSeleccionada.tipo_autenticacion === "login") {
        return <Navigate to="/login" replace />;
    }

    return children;
}

/**
 * Carga CSS externo
 * @param {string[]} cssUrls - URLs de los archivos CSS a cargar
 */
const loadExternalCSS = (cssUrls) => {
    cssUrls.forEach(url => {
        const link = document.createElement('link');
        link.href = url;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    });
}

/**
 * Componente principal de la aplicación
 */
const App: React.FC = () => {
    const {seleccionarCuenta} = useInicializadorApp();
    const {actualizarConversaciones} = useConversaciones();
    const {usuario, tokenSistemaValido}= useAuthContext();

    const {
        appBootstrapping, errorSistema,
        cuentas,
        cuentaSeleccionada, setCuentaSeleccionada,
        setAsistenteSeleccionado,
        setConversaciones,
        setConversacionSeleccionada,
        accountConfig,
        mostrarSidebar,
        notifications, removeNotification
    } = useAppContext();

    const tema = useMemo(() => {
        if (accountConfig && accountConfig.theme) {
            let themeCompuesto = deepmerge(baseTheme, accountConfig.theme);
            let temaCreado = createTheme(themeCompuesto);
            if (accountConfig.externalCss) {
                loadExternalCSS(accountConfig.externalCss);
            }
            return temaCreado;
        }
        return createTheme(baseTheme);
    }, [accountConfig]);

    const [toolbarIcons, setToolbarIcons] = useState<Array<{ icon: React.ReactNode, onClick: () => void }>>([]);

    /**
     * Reinicia la cuenta seleccionada
     * @param {Function} navigate - Función de navegación
     */
    const resetSelectedAccount = (navigate) => {
        setCuentaSeleccionada(null);
        setAsistenteSeleccionado(null);
        setConversaciones(null);
        setConversacionSeleccionada(null);
        navigate('/');
    }

    /**
     * Selecciona una cuenta
     * @param {Cuenta} cuenta - Cuenta a seleccionar
     */
    const selectAccount = async (cuenta: Cuenta) => {
        seleccionarCuenta(cuenta);
    }

    if (errorSistema) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <Alert severity="error" icon={<FontAwesomeIcon icon={faExclamationTriangle}/>}
                       style={{width: "50%", marginTop: "100px", border: "1px solid #d32f2f"}}>
                    <h2 style={{marginTop: 0}}>{errorSistema}</h2>
                    <p>
                        Lo sentimos por las molestias. <br/>
                        Por favor, inténtelo de nuevo más tarde o comuniquese con soporte.
                    </p>
                </Alert>
            </Box>
        );
    }

    if (appBootstrapping || !cuentaSeleccionada) {
        return <Loader/>;
    }

    return (
        <ThemeProvider theme={tema}>
            <Box className="App" sx={{
                display: 'flex',
                backgroundColor: tema.palette.background.default
            }}>
                <Sidebar
                    mostrarSwitcher={cuentas.length > 1}
                    mostrarSidebar={mostrarSidebar}
                />

                <main style={{
                    flexGrow: 1,
                    order: accountConfig?.sidebar && accountConfig.sidebar.anchor === 'left' ? 2 : 1
                }}>
                    <Box>
                        {notifications.map((notification) => (
                            <Notification
                                key={notification.id}
                                open={true}
                                message={notification.message}
                                severity={notification.severity}
                                onClose={() => removeNotification(notification.id)}
                            />
                        ))}

                        <Routes>
                            <Route path="/" element={<RutaProtegida><Home/></RutaProtegida>}/>
                            <Route path="/login" element={<LoginPanel />} />
                            <Route path="/logout" element={<LogoutRoute />} />
                            <Route path="/nueva-conversacion"
                                   element={<RutaProtegida><Chat actualizarSidebar={actualizarConversaciones}
                                                  setToolbarIcons={setToolbarIcons}
                                   /></RutaProtegida>}/>
                            <Route path="/ver-conversacion/:conversationId"
                                   element={<RutaProtegida><Chat actualizarSidebar={actualizarConversaciones}
                                                  setToolbarIcons={setToolbarIcons}
                                   /></RutaProtegida>}/>
                        </Routes>
                    </Box>
                </main>
            </Box>
            <DebugBar/>
        </ThemeProvider>
    );
};

export default App;