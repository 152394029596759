export class MessageDto {
    isUser: boolean;
    id: string;
    content: string;
    files: string[];
    fecha_creacion?: string;
    esFavorito?: boolean;
    valoracion?: number;
    fueCancelado?: boolean;

    constructor(
        id: string,
        isUser: boolean,
        content: string,
        files?: string[],
        fecha_creacion?: string,
        es_favorito?: boolean,
        valoracion?: number,
        fue_cancelado?: boolean) {

        this.id = id;
        this.isUser = isUser;
        this.content = content;
        this.files = files;
        this.fecha_creacion = fecha_creacion;
        this.esFavorito = es_favorito;
        this.valoracion = valoracion;
        this.fueCancelado = fue_cancelado;
    }

}
