import {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {debounce} from 'lodash';
import useConversaciones from './useConversaciones';
import {useAppContext} from "hooks/useAppContext";
import { useAuthContext } from "contexts/AuthContext";
import { useInView } from 'react-intersection-observer';

// Definimos el tipo para un observer
type Observer = IntersectionObserver;

// Definimos el tipo para el objeto de observers
type ObserversRef = {
    [key: string]: Observer;
};

// Definir el tipo de los grupos
interface Group {
    id: string;
    label: string;
}


const useSidebarList = () => {
    const {
        conversacionesAgrupadas,
        contarConversacionesAgrupadas,
        hasMore,
        actualizarConversaciones,
        setPage
    } = useConversaciones();

    const {usuario} = useAuthContext();
    const {cuentaSeleccionada} = useAppContext();
    const [currentGroup, setCurrentGroup] = useState<Group | null>(null);
    const [visibleGroups, setVisibleGroups] = useState<{ [key: string]: boolean }>({});
    const currentGroupRef = useRef<Group | null>(null);
    const orderedGroups: Group[] = [
        {id: 'hoy', label: 'Hoy'},
        {id: 'ayer', label: 'Ayer'},
        {id: 'ultimos-7-dias', label: 'Ultimos 7 días'},
        {id: 'ultimos-30-dias', label: 'Ultimos 30 días'},
        {id: 'anterior', label: 'Anterior'}
    ];
    const observerRefs = useRef<ObserversRef>({});

    const [loading, setLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);


    // Función para determinar el grupo inicial
    const determineInitialGroup = useCallback(() => {
        return orderedGroups.find(group => conversacionesAgrupadas[group.label]?.length > 0) || null;
    }, [conversacionesAgrupadas]);

    const fetchMoreData = useCallback(() => {
        if (!loading && hasMore) {
            setLoading(true);
            actualizarConversaciones().finally(() => {
                setLoading(false);
            });
        }
    }, [hasMore, loading]);

    const obtenerGrupoPorEtiqueta = useCallback((label: string) => {
        return orderedGroups.find(group => group.label === label);
    }, [orderedGroups]);

    const handleVisibilityChange = (entries: IntersectionObserverEntry[]) => {
        // console.log('visibility change', entries);
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const groupId = entry.target.getAttribute('data-group-id');
                const group = orderedGroups.find(g => g.id === groupId);
                if (group && group.id !== currentGroupRef.current?.id) {
                    setCurrentGroup(group);
                    currentGroupRef.current = group;
                }
            }
        });
    };

    const inicializarListado = useCallback(async () => {
        console.log("inicializar listado");
        setLoading(true);
        setCurrentGroup(null);
        currentGroupRef.current = null;
        setPage(1);
        await actualizarConversaciones(cuentaSeleccionada?.id, true);
        setLoading(false);
    }, [usuario, cuentaSeleccionada]);


    useEffect(() => {
        if (isInitialLoad && Object.keys(conversacionesAgrupadas).length > 0) {
            const initialGroup = determineInitialGroup();
            if (initialGroup) {
                // console.log("Inicializando grupo", initialGroup.label);
                setCurrentGroup(initialGroup);
                currentGroupRef.current = initialGroup;
                setIsInitialLoad(false);
            }
        }
    }, [conversacionesAgrupadas, isInitialLoad, determineInitialGroup]);


    return {
        conversacionesAgrupadas,
        contarConversacionesAgrupadas,
        currentGroup,
        visibleGroups,
        loading,
        fetchMoreData,
        hasMore,
        isInitialLoad,
        obtenerGrupoPorEtiqueta,
        currentGroupRef,
        setCurrentGroup,
        orderedGroups,
        inicializarListado
    };
};

export default useSidebarList;