import {Alert, Box, Button, Typography} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import {FormatQuote, Refresh} from "@mui/icons-material";
import React from "react";

type MessageErrorPanelProps = {
    mostrarReload: boolean;
    callbackReload?: CallableFunction;
    mensajeError: string;

}

const MessageErrorPanel = (props: MessageErrorPanelProps) => {
    const {mostrarReload, callbackReload, mensajeError} = props;
    // console.log("mensaje error", mensajeError);
    return (
        <Box>
            <Alert
                severity="error"
                icon={<ErrorIcon/>}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgba(211, 47, 47, 0.04)',
                    color: 'error.dark',
                    borderRadius: 2,
                    marginBottom: 3,
                    padding: 2,
                    '& .MuiAlert-icon': {
                        color: 'error.main',
                        opacity: 0.9,
                        marginRight: 2,
                    },
                }}
            >
                <Typography variant="h3">
                    Se ha producido un error. Por favor, inténtelo de nuevo más tarde.
                </Typography>

                <Typography variant="body2" sx={{mt: 0.5}}>
                    {mensajeError}
                </Typography>
            </Alert>
            {mostrarReload && (
                <Button sx={{marginTop: 2}} variant={"outlined"} onClick={() => {
                    callbackReload();
                }} style={{marginTop: "10px", fontSize: "0.9rem"}}>
                    {/*add refresh icon*/}
                    <Refresh sx={{marginRight: "5px"}}/>

                    Volver a intentar</Button>
            )}
        </Box>
    )
}

export default MessageErrorPanel;