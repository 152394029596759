// src/services/servicioAutenticacion.ts

import { Usuario } from "../types/Usuario";

export const CLAVE_TOKEN_JWT = 'token_jwt';
export const CLAVE_EXPIRACION_TOKEN = 'expiracion_token';
export const CLAVE_ULTIMO_TOKEN_USADO = 'ultimo_token_usado';
export const apiUrl = process.env.REACT_APP_API_URL;
let tokenEnProceso = null;

/**
 * Servicio de Autenticación (authService)
 *
 * Este servicio maneja la autenticación y gestión de tokens para la aplicación.
 * Proporciona métodos para intercambiar tokens, almacenar y recuperar tokens JWT,
 * y verificar el estado de autenticación del usuario.
 *
 * Funcionamiento principal:
 *
 * 1. Intercambio de Tokens:
 *    - El método `intercambiarToken` recibe un token de usuario y lo intercambia
 *      por un token JWT válido mediante una llamada a la API.
 *    - Soporta la regeneración forzada de tokens para manejar casos de múltiples cuentas.
 *
 * 2. Almacenamiento de Tokens:
 *    - Los tokens JWT y su tiempo de expiración se almacenan en el localStorage.
 *    - Se guarda también el último token de usuario utilizado para generar el JWT.
 *
 * 3. Verificación de Tokens:
 *    - El método `obtenerToken` verifica la validez del token almacenado antes de devolverlo.
 *    - Si el token ha expirado, se elimina automáticamente.
 *
 * 4. Información del Usuario:
 *    - `obtenerInfoUsuario` recupera la información del usuario autenticado desde la API.
 *
 * 5. Gestión de Estado de Autenticación:
 *    - `estaAutenticado` proporciona una forma rápida de verificar si existe un token válido.
 *
 * Uso de localStorage:
 * - CLAVE_TOKEN_JWT: Almacena el token JWT.
 * - CLAVE_EXPIRACION_TOKEN: Almacena la fecha de expiración del token JWT.
 * - CLAVE_ULTIMO_TOKEN_USADO: Guarda el último token de usuario utilizado para la autenticación.
 *
 * Notas importantes:
 * - El servicio maneja automáticamente la expiración de tokens.
 * - Soporta la regeneración forzada de tokens para casos especiales de múltiples cuentas.
 * - Todas las operaciones de red utilizan la URL base definida en REACT_APP_API_URL.
 */
export const authService = {
    /**
     * Método para intercambiar el token del usuario por el token de autenticación del sistema.
     * @param token
     * @param forzarRegeneracion
     */
    async intercambiarToken(token: string, forzarRegeneracion: boolean = false): Promise<string> {
        if (tokenEnProceso === token) {
            // Si este token ya está siendo procesado, esperamos y devolvemos el resultado
            while (tokenEnProceso === token) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            return this.obtenerToken();
        }
        const ultimoTokenUsado = localStorage.getItem(CLAVE_ULTIMO_TOKEN_USADO);

        if (!forzarRegeneracion && token === ultimoTokenUsado && this.obtenerToken()) {
            return this.obtenerToken();
        }

        try {
            tokenEnProceso = token;

            const formData = new FormData();
            formData.append('token', token);

            const respuesta = await fetch(`${apiUrl}/auth/autenticar`, {
                method: "POST",
                body: formData,
            });

            if (!respuesta.ok) {
                throw new Error("Error al autenticar el token de usuario.");
            }

            const datos = await respuesta.json();
            if (!datos.token_jwt) {
                throw new Error("El token de usuario no es válido");
            }

            this.guardarToken(datos.token_jwt, datos.expiracion);
            localStorage.setItem(CLAVE_ULTIMO_TOKEN_USADO, token);

            return datos.token_jwt;
        } finally {
            tokenEnProceso = null;
        }
    },


    /**
     * Método para autenticar al usuario con nombre de usuario y contraseña.
     * @param username
     * @param password
     */
    async autenticarConCredenciales(username: string, password: string): Promise<string> {
        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const respuesta = await fetch(`${apiUrl}/auth/autenticar`, {
                method: "POST",
                body: formData,
            });

            if (!respuesta.ok) {
                throw new Error("Error al autenticar con credenciales.");
            }

            const datos = await respuesta.json();
            if (!datos.token_jwt) {
                throw new Error("Las credenciales no son válidas");
            }

            this.guardarToken(datos.token_jwt, datos.expiracion);
            // No guardamos el último token usado ya que no se usa un token de usuario

            return datos.token_jwt;
        } catch (error) {
            console.error("Error en autenticación con credenciales:", error);
            throw error;
        }
    },

    guardarToken(token: string, expiracion: number): void {
        localStorage.setItem(CLAVE_TOKEN_JWT, token);
        if (expiracion) {
            localStorage.setItem(CLAVE_EXPIRACION_TOKEN, expiracion.toString());
        }
    },

    obtenerToken(): string | null {
        if (this.tokenHaExpirado()) {
            this.eliminarToken();
            return null;
        }
        return localStorage.getItem(CLAVE_TOKEN_JWT);
    },

    eliminarToken(): void {
        localStorage.removeItem(CLAVE_TOKEN_JWT);
        localStorage.removeItem(CLAVE_EXPIRACION_TOKEN);
        localStorage.removeItem(CLAVE_ULTIMO_TOKEN_USADO);
    },

    tokenHaExpirado(): boolean {
        const expiracion = localStorage.getItem(CLAVE_EXPIRACION_TOKEN);
        if (!expiracion) return true;

        let timestamp = Date.parse(expiracion);
        if (isNaN(timestamp)) {
            return true;
        }

        let ahora = Date.now();
        let tokenExpirado = ahora > timestamp;

        return tokenExpirado;
    },

    obtenerUltimoTokenUsado(): string | null {
        return localStorage.getItem(CLAVE_ULTIMO_TOKEN_USADO);
    },

    async obtenerInfoUsuario(debug: boolean = false): Promise<{ usuario: Usuario | null, timezone: string | null, debugData: any | null }> {
        const token = this.obtenerToken();
        if (!token) {
            return { usuario: null, timezone: null, debugData: null };
        }

        let urlInfo = !debug
            ? `${apiUrl}/auth/obtener-info-usuario`
            : `${apiUrl}/auth/obtener-info-usuario?debug=1`;

        const respuesta = await fetch(urlInfo, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!respuesta.ok) {
            throw new Error("Error al obtener información del usuario");
        }

        const datos = await respuesta.json();

        let infoUsuario = {
            usuario: datos.usuario,
            timezone: datos.timezone,
            debugData: null
        };

        if (debug) {
            infoUsuario["debugData"] = datos["debug"];
        }

        return infoUsuario;
    },

    estaAutenticado(): boolean {
        return !!this.obtenerToken();
    },

    // Método para verificar y renovar el token si es necesario
    async verificarYRenovarToken(): Promise<void> {
        if (this.tokenHaExpirado()) {
            // Aquí podrías implementar la lógica para renovar el token
            // Por ejemplo, hacer una llamada al servidor para obtener un nuevo token
            // Por ahora, simplemente eliminaremos el token expirado
            this.eliminarToken();
        }
    }
};