import {Box, CircularProgress} from "@mui/material";
import {useAppContext} from "../../hooks/useAppContext";
import { PropsComponenteDinamico } from "../../types/PropsComponenteDinamico";
import React, {useMemo} from "react";
import VisualizadorPlotly from "../llm/VisualizadorPlotly";



const HelloWorldDynamicComponent = ({ payload }: PropsComponenteDinamico) => {
    // Ahora puedes usar hooks aquí
    // const [estado, setEstado] = React.useState(null);

    return <Box>Hello World! 🥳</Box>;
};


// Objeto de componentes por defecto
const componentesDefault = {
    'HelloWorld': HelloWorldDynamicComponent,
    'VisualizadorPlotly': VisualizadorPlotly,
    // Añade aquí más componentes por defecto
};

const ControladorComponenteDinamicoLLM: React.FC<{ cfgComponente: any }> = ({ cfgComponente }) => {
    const { accountConfig } = useAppContext();

    // Usamos useMemo para memoizar los componentes combinados
    const componentesRegistrados = useMemo(() => {
        return {
            ...componentesDefault,
            ...((accountConfig?.componentesDinamicos) || {})
        };
    }, [accountConfig?.componentesDinamicos]);

    // todavia no esta terminado y no puede renderizar
    if (!cfgComponente || !cfgComponente.yais_componente) {
        return <CircularProgress sx={{mt: 2, mb:3, ml: 2}}  />;
    }


    const ComponenteDinamico = componentesRegistrados[cfgComponente.yais_componente];

    if (ComponenteDinamico) {
        return <ComponenteDinamico payload={cfgComponente.payload} />;
    }

    return <Box>Componente no reconocido: {cfgComponente.yais_componente}</Box>;
};

export default ControladorComponenteDinamicoLLM;