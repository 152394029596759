import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../contexts/AuthContext";
import {useAppContext} from "hooks/useAppContext";

const LogoutRoute = ({}) => {

    const {usuario, cerrarSesion} = useAuthContext()
    const {setIdConversacion, setConversaciones} = useAppContext();

    const navigate = useNavigate();

    /**
     * Se ejecuta cuando el usuario se desconecta
     * Solo corre si esta autenticado el usuario. También se encarga de vaciar las conversaciones y la conversacion seleccionada
     */
    useEffect(() => {
        if (!usuario) {
            navigate("/login");
        }

        setIdConversacion(null);
        setConversaciones([]);
        cerrarSesion();
        navigate("/");
    }, [usuario]);

    return (<></>);

}

export default LogoutRoute;