
// Extiende la interfaz PaletteOptions para incluir tu nueva propiedad
import {SimplePaletteColorOptions} from "@mui/material/styles/createPalette";

declare module '@mui/material/styles' {
    interface PaletteOptions {
        backgroundListaMensajes?: SimplePaletteColorOptions;
        backgroundItemSidebar?: SimplePaletteColorOptions;
        backgroundSidebar?: SimplePaletteColorOptions;
    }


    interface Palette {
        backgroundItemSidebar?: SimplePaletteColorOptions;
        backgroundListaMensajes?: SimplePaletteColorOptions;
        backgroundSidebar?: SimplePaletteColorOptions;
    }

}


// Crea un tema personalizado
const baseTheme = {
    palette: {
        primary: {
            main: "#000",
        },
        secondary: {
            main: "#F87329",
        },
        error: {
            main: "#b92626",
        },
        background: {
            default: "#FFF",
        },
        backgroundListaMensajes: {
            main: "#FFF",
        },
        backgroundItemSidebar: {
            main: "#F0F0EE"
        },
        backgroundSidebar: {
            main: "transparent"
        }
    },
    typography: {
        fontSize: 16,
        h2: {
            fontWeight: 'bold', // Define el peso de la fuente como negrita
            fontSize: '2rem', // Define el tamaño de la fuente
            lineHeight: 1.5, // Define la altura de línea
            color: '#333', // Define el color del texto
            // Agrega más estilos según sea necesario
        },
        h4: {
            marginBottom: '0'
        }
    }
}

export default baseTheme