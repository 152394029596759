import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Fade,
    List,
    ListItem,
    Modal,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {useAppContext} from "../hooks/useAppContext";
import {ExpandMore} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAuthContext} from "../contexts/AuthContext";
import {CLAVE_TOKEN_JWT, CLAVE_EXPIRACION_TOKEN, CLAVE_ULTIMO_TOKEN_USADO} from "../services/authService";

function nl2br(text) {
    return text.split('\n').map((item, key) => {
        return <React.Fragment key={key}>{item}<br/></React.Fragment>
    });
}

const DebugBar = () => {

    // debug vars
    const [openDebugPanel, setOpenDebugPanel] = React.useState(false);
    const [activeAccordion, setActiveAccordion] = React.useState<string | null>(null);
    const {usuario, tokenSistema, tokenUsuario} = useAuthContext();
    const {debugInfo, asistenteSeleccionado, conversacionSeleccionada, idConversacion} = useAppContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenDebug = () => {
        setOpenDebugPanel(true);
    };

    const handleCloseDebug = () => {
        setOpenDebugPanel(false);
    };

    const obtenerLocalStorageChat = () => {
        return window.localStorage.getItem("chat_token");
    }

    const obtenerLocalStorage = () => {
        return {
            CLAVE_TOKEN_JWT: window.localStorage.getItem(CLAVE_TOKEN_JWT),
            CLAVE_EXPIRACION_TOKEN: window.localStorage.getItem(CLAVE_EXPIRACION_TOKEN),
            CLAVE_ULTIMO_TOKEN_USADO: window.localStorage.getItem(CLAVE_ULTIMO_TOKEN_USADO)
        };
    }

    const handleAccordionChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setActiveAccordion(isExpanded ? panel : null);
    };

    useEffect(() => {
        // console.log("debugInfo", debugInfo)
    }, [debugInfo]);

    return (
        <Box>
            <Box
                onDoubleClick={handleOpenDebug}
                onTouchStart={handleOpenDebug}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    width: 30,
                    height: 30,
                    backgroundColor: 'transparent'
                }}
            />

            <Modal
                open={openDebugPanel}
                onClose={handleCloseDebug}
                closeAfterTransition
                sx={{
                    display: 'flex',
                    alignItems: 'top',
                    justifyContent: 'center',
                }}
            >
                <Fade in={openDebugPanel}>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: isMobile ? "90%" : 800,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            mt: 10
                        }}
                    >
                        <Typography variant="h2" component="h2">Debug Info</Typography>

                        {debugInfo["app"] && (
                            <Accordion expanded={activeAccordion === 'app'} onChange={handleAccordionChange('app')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography>App</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {Object.keys(debugInfo["app"]).map((key, index) => (
                                            <ListItemTypography key={key + "_" + index}>
                                                {key} : {debugInfo["app"][key] !== null && debugInfo["app"][key] !== undefined ? String(debugInfo["app"][key]) : 'Valor no disponible'}
                                            </ListItemTypography>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )}

                        {asistenteSeleccionado && (
                            <Accordion expanded={activeAccordion === 'asistente'}
                                       onChange={handleAccordionChange('asistente')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography>Asistente</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {["id", "nombre", "js_module", "python_module"].map((key) => (
                                            <ListItemTypography
                                                key={key}>{key}: {asistenteSeleccionado[key]}</ListItemTypography>
                                        ))}

                                        <ListItemTypography>
                                            <Box
                                                sx={{maxHeight: "200px", overflowY: "scroll"}}>

                                                {nl2br(asistenteSeleccionado["instrucciones"])}
                                            </Box>
                                        </ListItemTypography>
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )}

                        {usuario && (
                            <Accordion expanded={activeAccordion === 'usuario'}
                                       onChange={handleAccordionChange('usuario')}>

                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography>Usuario</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{maxHeight: "200px", overflowY: "scroll"}}>
                                        <List>

                                            {Object.keys(usuario).map((key) => (
                                                <ListItemTypography key={key}>
                                                    {/*check if value is string printed if not loop through keys*/}
                                                    {typeof usuario[key] === "string" || typeof usuario[key] === "number" ? (
                                                        <Box>{key}: {usuario[key]}</Box>
                                                    ) : (
                                                        // Manejo los objetos anidados de datos_externos_json
                                                        <Box key={"box_" + key}>
                                                            {key}:<br/>
                                                            <Box>
                                                                {Object.keys(usuario[key]).map((subKey, index) => (
                                                                    <Box key={"subbox_" + index}>
                                                                        {subKey}: {usuario[key][subKey]}
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    )}

                                                </ListItemTypography>
                                            ))}


                                            <ListItemTypography>
                                                Token Sistema: {tokenSistema}
                                            </ListItemTypography>

                                            {Object.entries(obtenerLocalStorage()).map(([key, value], index) => (
                                                <ListItemTypography key={index}>
                                                    {key}: {value}
                                                </ListItemTypography>
                                            ))}

                                        </List>

                                        {debugInfo["usuario"] && (
                                            <List>
                                                <ListItem>
                                                    <Box width={"100%"}>
                                                        <Typography>
                                                            Archivos disponibles:
                                                        </Typography>
                                                    </Box>
                                                    <Box width={"100%"}>
                                                        {debugInfo["usuario"]["archivos_disponibles"] && debugInfo["usuario"]["archivos_disponibles"].map((archivo: any, index) => {
                                                            return (
                                                                <Box>
                                                                    <Typography
                                                                        sx={{display: "block"}}
                                                                        key={"archivos_disponibles_" + index}>{archivo.id}
                                                                    </Typography>
                                                                </Box>)
                                                        })}
                                                    </Box>
                                                </ListItem>
                                            </List>
                                        )}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )}

                        {debugInfo["conversacion"] && (
                            <Accordion expanded={activeAccordion === 'conversacion'}
                                       onChange={handleAccordionChange('conversacion')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography>Conversación</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {Object.keys(debugInfo["conversacion"]).map((key) => !debugInfo["conversacion"][key] || typeof debugInfo["conversacion"][key] == "string" ? (
                                            <ListItemTypography
                                                key={key}>{key}:<br/>{debugInfo["conversacion"][key] ? nl2br(debugInfo["conversacion"][key]) : debugInfo["conversacion"][key]}
                                            </ListItemTypography>
                                        ) : (
                                            <ListItemTypography key={key}>
                                                {key}: <br/>
                                                <Box>
                                                    {debugInfo["conversacion"][key].map((item: any) => {
                                                        return <Typography
                                                            sx={{display: "block", width: "100%"}}>{item}</Typography>
                                                    })}
                                                </Box>
                                            </ListItemTypography>
                                        ))}

                                        <ListItemTypography>
                                            ID Conversación: {idConversacion}
                                        </ListItemTypography>

                                        <ListItemTypography>
                                            Conversacion Seleccionada: {conversacionSeleccionada ? (
                                            <Box>
                                                ID: {conversacionSeleccionada.id}
                                            </Box>
                                        ) : "No hay conversacion seleccionada"}
                                        </ListItemTypography>
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </Box>
    )
}

const ListItemTypography = ({children, ...props}) => {
    const theme = useTheme();
    return (
        <ListItem {...props}>
            {children && (
                <Box sx={{...theme.typography.body2}} style={{overflowWrap: 'anywhere', whiteSpace: 'pre-wrap'}}>
                    {children}
                </Box>
            )}
        </ListItem>
    );
};

export default DebugBar;