import React, {useState} from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import {Box, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Star} from "@mui/icons-material";

const PanelUsuario = ({usuario, handleLogout, accountConfig}) => {

    const [anchorEl, setAnchorEl] = useState(null);


    const defaultMenuItems = [
        {
            label: 'Tus favoritos',
            icon: <Star fontSize="small"/>,
            action: () => {
                alert("favoritos");
            }
        },
        {
            label: 'Cerrar sesión',
            icon: <LogoutIcon fontSize="small"/>,
            action: handleLogout
        }
    ];

    const customMenuItems = accountConfig?.sidebar?.itemsMenuUsuario || [];
    const allMenuItems = [...customMenuItems, ...defaultMenuItems];


    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box mt="auto" borderTop={"1px solid #efefef"} display="flex" alignItems="center" justifyContent="space-between"
             padding="10px 20px" sx={{
            backgroundColor: "#333333",
            color: "white",
        }}>
            <Typography>
                Hola {usuario["nombre"]}
            </Typography>
            <Box>
                <IconButton
                    aria-controls="user-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    sx={{
                        color: 'white',
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        elevation: 3,
                        sx: {
                            minWidth: '200px',
                            borderRadius: '8px',
                            padding: '8px 0',
                        },
                    }}
                >
                    {allMenuItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                if (item.url) {
                                    window.open(item.url, '_blank');
                                } else if (item.action) {
                                    item.action();
                                }
                                handleMenuClose();
                            }}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                padding: '12px 16px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            {item.icon}
                            <Typography>{item.label}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </Box>
    )
}

export default PanelUsuario;